<template>
  <div class="inner-chart-block" ref="multiLine">
  <svg  id="multi-line-report"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { itemColors } from '@/util/util.js'
import { fullScreenMode } from '../../util/util'

/** This component renders a multi-line chart that shows the distribution of reviews of a selected period of time for all products selected */
export default {
  name: 'ReportMultiline',
  props: {
    item: String
  },
  data () {
    return {
      data: [],
      observer: null
    }
  },
  computed: {
    ...mapState('report', ['stack']),
    ...mapState('common', ['reportFullscreen', 'currentModule']),
    ...mapState('filters', ['selectedProducts', 'selectedCompaniesVoe', 'ActiveVocTimePeriod', 'ActiveVoeTimePeriod']),
    ActiveTimePeriod () {
      if (this.currentModule === 'voc') {
        return this.ActiveVocTimePeriod
      } else {
        return this.ActiveVoeTimePeriod
      }
    }

  },
  watch: {
    stack () {
      this.renderChart()
    }
  },
  mounted () {
    if (this.stack.length) {
      this.renderChart()
    }
    this.resizeWindow()
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    ...mapActions('filters', [
      'updateSelectedProducts',
      'updateSelectedCompaniesVoe',
      'updateActiveVoeTimePeriod',
      'updateActiveVocTimePeriod'
    ]),
    ...mapActions('common', ['toggleReportFullscreen', 'resetReportFullscreen']),
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (this.stack.length) {
          if (d3.select('.inner-chart-block').node() != null) {
            this.renderChart()
          }
        }
      })
      this.observer.observe(this.$refs.multiLine)
    },
    /** This method renders the chart
     * @public
     */
    renderChart () {
      d3.selectAll('#multi-line-report > *').remove()
      const currentModule = this.currentModule
      const ActiveTimePeriod = this.ActiveTimePeriod.group_by
      const timeRange = this.ActiveTimePeriod.range
      const item = this.item
      let quarterOrdinalList = []
      const items = [
        ...new Set(this.stack.map((item) => item[`${this.item}_name`]))
      ]
      // const dates = []
      // for (const data of this.stack) {
      //   dates.push(
      //     moment(`${data.month}-${data.year_of_review}`, 'M-YYYY').format(
      //       'YYYY-MM-DD'
      //     )
      //   )
      // }
      // ................................................................
      function listQuarters (sDate, eDate) {
        let quarterList = []
        let q = sDate.quarter()
        while (sDate.isBefore(eDate)) {
          q = sDate.quarter()
          quarterList.push(moment(q + '-' + sDate.year(), 'M-YYYY').format('YYYY-MM-DD'))
          quarterOrdinalList.push('Q' + q + moment(sDate.year(), 'YYYY').format(' \'YY'))
          sDate.add(1, 'month')
        }
        quarterList = [...new Set(quarterList)]
        quarterOrdinalList = [...new Set(quarterOrdinalList)]
        return quarterList
      }
      let data = [] // [...new Set(dates.map((item) => item))].sort()
      const startDate = moment(timeRange[0], 'YYYY-MM-DD') // moment(data[0], 'YYYY-MM-DD')
      const endDate = moment(timeRange[1], 'YYYY-MM-DD') // moment(data[data.length - 1], 'YYYY-MM-DD')
      let result = []
      if (ActiveTimePeriod === 'month') {
        while (startDate.isBefore(endDate)) {
          result.push(startDate.format('YYYY-MM-DD'))
          startDate.add(1, 'month')
        }
      } else {
        result = listQuarters(startDate, endDate)
        // listOrdinalQuarters(startDate, endDate)
      }
      data = [...result].sort()
      const finalLineData = []
      for (const itemName of items) {
        const temp = {}
        temp.name = itemName
        temp.values = this.stack.filter((e) => e[`${item}_name`] === itemName)
        finalLineData.push(temp)
      }

      const tooltip = d3.select('.mltooltip')

      const marginGeneral = {
        top: 30,
        right: 40,
        bottom: 32,
        left: 40
      }
      const width =
        d3.select('.inner-chart-block').node().getBoundingClientRect().width
      const height =
        d3.select('.inner-chart-block').node().getBoundingClientRect().height

      const circleOpacity = '0.85'
      const circleRadius = 3

      /* Format Data */
      const parseDate = function (date) {
        return moment(date, 'YYYY-MM-DD')
      }
      finalLineData.forEach(function (d) {
        d.values.forEach(function (d) {
          const date = moment(
            `${d.month}-${d.year_of_review}`,
            'M-YYYY'
          ).format('YYYY-MM-DD')
          d.date = moment(date)
          d.volume = +d.review_volume
        })
      })

      // Filling the balnks.
      finalLineData.forEach(function (f) {
        if (f.values.length !== data.length) {
          let found = null
          data.forEach(function (d) {
            found = f.values.find(
              (e) => {
                return moment(e.date).format('YYYY-MM-DD') === d
              }
            )
            if (!found) {
              const temp = {
                csat_rating: 0,
                date: moment(d),
                month: moment(d, 'YYYY-MM-DD').month() + 1,
                [`${item}_name`]: f.name,
                review_volume: '0',
                volume: 0,
                year_of_review: moment(d, 'YYYY-MM-DD').year()
              }
              const bisectMonth = d3.bisector(function (d) {
                return d.date
              }).left
              const pos = bisectMonth(
                f.values,
                moment(d)
              )
              f.values.splice(pos, 0, temp)
            }
          })
        }
      })
      /* Scale */
      const xScale = d3
        .scaleTime()
        .domain(
          d3.extent(data, (d) => {
            return moment(d)
          })
        )
        .range([marginGeneral.left, width - 20])

      const x = d3.scalePoint()
        .domain(quarterOrdinalList)
        .range([marginGeneral.left, width - 20])
      const maxVolume = d3.max(finalLineData, function (d) {
        return d3.max(d.values, function (e) {
          return e.volume
        })
      })
      const yScale = d3
        .scaleLinear()
        .domain([0, maxVolume])
        .range([height - marginGeneral.bottom, marginGeneral.top])
        .nice()

      const svg = d3
        .select('#multi-line-report')
        .attr('width', width)
        // .attr('width', width-20)            TODO
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${0}, 0)`)

      let xAxis
      if (ActiveTimePeriod === 'quarter') {
        xAxis = d3
          .axisBottom(x)
          .tickFormat(function (d, i) {
            return d
            // return 'Q' + (d.getMonth() + 1) + ' ' + moment(d.getFullYear(), 'YYYY').format('\'YY') // old working
          })
          // .ticks(d3.timeMonth.every(3))
      } else {
        xAxis = d3
          .axisBottom(xScale)
          .tickFormat(d3.timeFormat('%b \'%y'))
          .ticks(d3.timeMonth)
      }

      let yAxis = null
      if (maxVolume > 10) {
        yAxis = d3.axisLeft(yScale).ticks(6)
      } else if (maxVolume > 5) {
        yAxis = d3
          .axisLeft(yScale)
          .tickFormat(d3.format('.0f'))
          .tickValues([0, 2, 4, 6, 8, 10])
      } else {
        yAxis = d3
          .axisLeft(yScale)
          .tickFormat(d3.format('.0f'))
          .tickValues([0, 1, 2, 3, 4, 5])
      }

      svg
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(${0}, ${height - marginGeneral.bottom})`)
        .call(xAxis)
      svg
        .selectAll('.x-axis')
        .selectAll('text')
        .attr('fill', 'var(--reports-label-color)')
        .attr('font-family', 'Quicksand, sans-serif')
        .attr('font-size', 12)
        .attr('fill-opacity', '0.8')
      svg
        .select('path.domain')
        .attr('stroke', 'var(--axis-color)')
        .attr('stroke-opacity', '0.8')
      svg
        .selectAll('.tick')
        .select('line')
        .attr('stroke', 'var(--axis-color)')
        .attr('stroke-opacity', '0.8')

      svg.append('g').attr('transform', `translate(${marginGeneral.left},0)`).attr('class', 'y-axis').call(yAxis)
      svg
        .selectAll('.y-axis')
        .selectAll('text')
        .attr('fill', 'var(--reports-label-color)')
        .attr('font-size', 12)
        .attr('font-family', 'Quicksand, sans-serif')
        .attr('fill-opacity', '0.8')
      svg
        .selectAll('.y-axis')
        .select('.domain')
        .attr('stroke', 'var(--axis-color)')
        .attr('stroke-opacity', '0.8')
      svg
        .selectAll('.tick')
        .select('line')
        .attr('stroke', 'var(--axis-color)')
        .attr('stroke-opacity', '0.8')

      function makeYGridlines () {
        return yAxis
      }

      svg
        .append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(${marginGeneral.left},0)`)
        .attr('id', 'multiline_lables')
        .call(
          makeYGridlines()
            .tickSize(-(width - marginGeneral.left - 18))
            .tickFormat('')
        )
        .style('stroke-dasharray', '4, 4')

      svg
        .selectAll('#multiline_lables')
        .selectAll('g')
        .select('line')
        .attr('stroke', 'var(--chart-background-dotted-line-color)')
        .attr('stroke-width', 1)
      svg.select('#multiline_lables').selectAll('path').remove()
      svg.select('.x-axis').selectAll('line').remove()
      svg.select('.y-axis').selectAll('path').remove()
      svg.select('.y-axis').selectAll('line').remove()

      svg
        .selectAll('#multiline_lables')
        .selectAll('g')
        .each(function (d, index) {
          if (index === 0) {
            d3.select(this).select('line').remove()
          }
        })

      // rect for mouse events
      const mouseG = svg.append('g').attr('class', 'mouse-over-effects')

      mouseG
        .append('path') // this is the black vertical line to follow mouse
        .attr('class', 'mouse-line')
        .style('stroke', 'gray')
        .style('stroke-width', '1px')
        .style('stroke-dasharray', '6, 3')
        .style('opacity', '0')

      /* Add line into SVG */
      const line = d3
        .line()
        .x((d) => {
          if (ActiveTimePeriod === 'month') {
            return xScale(moment(d.date))
          } else {
            return x('Q' + moment(d.date).format('M \'YY'))
          }
        }) // Q1 20
        .y((d) => yScale(+d.volume))

      const lines = svg.append('g').attr('class', 'lines')

      const drillDown = (event, d, type) => {
        if (this.reportFullscreen.status) {
          this.resetReportFullscreen()
          fullScreenMode(document, 'off')
        }
        const output = this.selectedProducts.filter(({ name }) => name === d[`${item}_name`])
        let range = []
        if (this.ActiveTimePeriod.group_by === 'quarter') {
          range = [moment(d.month + '-' + d.year_of_review, 'Q-YYYY').startOf('quarter').format('YYYY-MM-DD'), moment(d.month + '-' + d.year_of_review, 'Q-YYYY').endOf('quarter').format('YYYY-MM-DD')]
        } else {
          range = [moment(d.month + '-' + d.year_of_review, 'M-YYYY').startOf('month').format('YYYY-MM-DD'), moment(d.month + '-' + d.year_of_review, 'M-YYYY').endOf('month').format('YYYY-MM-DD')]
        }
        const timeObject = {}
        timeObject.group_by = this.ActiveTimePeriod.group_by
        timeObject.range = range
        if (currentModule === 'voc') {
          this.updateActiveVocTimePeriod(timeObject)
        } else if (currentModule === 'voe') {
          this.updateActiveVoeTimePeriod(timeObject)
        }
        if (output.length && currentModule === 'voc') {
          this.updateSelectedProducts([output[0]])
        } else if (output.length && currentModule === 'voe') {
          this.updateSelectedCompaniesVoe([output[0]])
        }
        tooltip.style('display', 'none')
        // tooltipFun(event, d, 'out')
        if (currentModule === 'voc') {
          this.$router.push({ name: 'comments' })
        } else if (currentModule === 'voe') {
          this.$router.push({ name: 'voeComments', path: 'comments' })
        }
      }

      lines
        .selectAll('.line-group')
        .data(finalLineData)
        .enter()
        .append('g')
        .attr('class', 'line-group')
        .append('path')
        .attr('class', 'line')
        .attr('pointer-events', 'none')
        .attr('d', function (d) {
          return line(d.values)
        })
        .style('stroke', (d, index) => {
          if (currentModule === 'voc') {
            return itemColors(
              this.selectedProducts.map((data) => data.name).indexOf(d.name)
            )
          } else if (currentModule === 'voe') {
            return itemColors(
              this.selectedCompaniesVoe.map((data) => data.name).indexOf(d.name)
            )
          }
        })
        .attr('fill', 'none')
        .attr('stroke-width', '2px')
      // .on('click', (event, d) => drillDown(event, d))

      /* Add circles in the line */
      lines
        .selectAll('circle-group')
        .data(finalLineData)
        .enter()
        .append('g')
        .style('fill', (d, i) => {
          if (currentModule === 'voc') {
            return itemColors(
              this.selectedProducts.map((data) => data.name).indexOf(d.name)
            )
          } else if (currentModule === 'voe') {
            return itemColors(
              this.selectedCompaniesVoe.map((data) => data.name).indexOf(d.name)
            )
          }
        })
        .selectAll('circle')
        .data((d) => d.values)
        .enter()
        .append('g')
        .attr('class', 'circle')
        .append('circle')
        .attr('cx', (d) => {
          if (ActiveTimePeriod === 'month') {
            return xScale(moment(d.date))
          } else {
            return x('Q' + moment(d.date).format('M \'YY'))
          }
        })
        .attr('cy', (d) => yScale(d.volume))
        .attr('r', circleRadius)
        .style('opacity', circleOpacity)
        .on('mouseout', function () {
          // on mouse out hide line, circles and text
          d3.select(this)
            .attr('r', circleRadius)
          d3.select('.mouse-line').style('opacity', '0')
          d3.selectAll('.mouse-per-line text').style('opacity', '0')
          tooltip.style('display', 'none')
        })
        .on('mouseover', function () {
          // on mouse in show line, circles and text
          d3.select(this)
            .attr('r', circleRadius * 2)
            .style('cursor', 'pointer')
          d3.select('.mouse-line').style('opacity', '1')
          tooltip.style('display', 'inline')
        })
        .on('mousemove', function (d) {
          if (ActiveTimePeriod === 'month') {
            mouseMoveMonthly(event)
          } else {
            mouseMoveQuarter(event)
          }
        })
        .on('click', (event, d) => drillDown(event, d))

      // const mouseG = svg.append('g').attr('class', 'mouse-over-effects')

      // mouseG
      //   .append('path') // this is the black vertical line to follow mouse
      //   .attr('class', 'mouse-line')
      //   .style('stroke', 'gray')
      //   .style('stroke-width', '1px')
      //   .style('stroke-dasharray', '6, 3')
      //   .style('opacity', '0')
      function getTopPosition (event) {
        const topNavHeight = 55
        const arrowHeight = 10
        const tooltipHeight = d3.select('.mltooltip').node().getBoundingClientRect().height + arrowHeight
        const tooltipHtml = document.querySelector('.mltooltip')
        // console.log(tooltipHtml)
        let top = 0
        const halfWidth = tooltip.node().getBoundingClientRect().width / 2
        if ((event.pageY - topNavHeight) < tooltipHeight) {
          tooltipHtml.classList.remove('bottom-arrow')
          tooltipHtml.classList.remove('right-arrow')
          tooltipHtml.classList.remove('right-bottom-arrow')
          tooltipHtml.classList.remove('right-top-arrow')
          tooltipHtml.classList.add('top-arrow')
          // if (!tooltipHtml.classList.contains('top-right-arrow')) {
          // }
          top = event.pageY + 25
        } else {
          tooltipHtml.classList.remove('top-arrow')
          tooltipHtml.classList.remove('right-arrow')
          tooltipHtml.classList.remove('right-bottom-arrow')
          tooltipHtml.classList.remove('right-top-arrow')
          tooltipHtml.classList.add('bottom-arrow')
          top = event.pageY - tooltip.node().getBoundingClientRect().height - 10
        }
        if ((window.innerWidth - event.pageX) < halfWidth) {
          top = event.pageY - (tooltip.node().getBoundingClientRect().height / 2)// - 10
        }
        return top
      }

      function getLeftPosition (event) {
        const tooltipHtml = document.querySelector('.mltooltip')
        const tooltipHeight = d3.select('.mltooltip').node().getBoundingClientRect().height + 10
        const halfWidth = tooltip.node().getBoundingClientRect().width / 2
        const topNavHeight = 55
        if ((window.innerWidth - event.pageX) > halfWidth) {
          return event.pageX - tooltip.node().getBoundingClientRect().width / 2
        } else {
          tooltipHtml.classList.remove('top-arrow')
          tooltipHtml.classList.remove('bottom-arrow')
          tooltipHtml.classList.remove('right-bottom-arrow')
          tooltipHtml.classList.remove('right-top-arrow')
          tooltipHtml.classList.add('right-arrow')
          if ((window.innerHeight - event.pageY) < (tooltipHeight / 2)) {
            // return event.pageY
            tooltipHtml.classList.remove('right-arrow')
            tooltipHtml.classList.add('right-bottom-arrow')
            tooltip
              .style('top', `${
                event.pageY - tooltip.node().getBoundingClientRect().height - 10
              }px`)
            return event.pageX - tooltip.node().getBoundingClientRect().width - 10
          } else if ((event.pageY - (topNavHeight)) < ((tooltip.node().getBoundingClientRect().height / 2) + 10)) {
            tooltipHtml.classList.remove('top-arrow')
            tooltipHtml.classList.remove('bottom-arrow')
            tooltipHtml.classList.remove('right-bottom-arrow')
            tooltipHtml.classList.add('right-top-arrow')
            tooltip
              .style('top', `${
                event.pageY + 10 // tooltip.node().getBoundingClientRect().height - 10
              }px`)
            return event.pageX - tooltip.node().getBoundingClientRect().width - 10
          }
          return event.pageX - tooltip.node().getBoundingClientRect().width - 10
        }
      }

      function mouseMoveMonthly (event) {
        // update tooltip content, line, circles and text when mouse moves
        const xco = event.offsetX
        d3.selectAll('.mouse-per-line').attr('transform', function (d) {
          const idx = getIdx(xco, d)
          const d0 = d.values[idx - 1] // || d.values[idx]
          const d1 = d.values[idx]
          let di = {}
          if (d0 && d1) {
            di = xco - xScale(moment(d0.date)) > xScale(moment(d1.date)) - xco
              ? d1
              : d0
          } else {
            di = d1
          }
          if (di) {
            d3.select('.mouse-line').attr('d', function () {
              let data =
                'M' +
                xScale(moment(di.date)) +
                ',' +
                (height - marginGeneral.bottom)
              data += ' ' + xScale(moment(di.date)) + ',' + marginGeneral.top
              return data
            })
            return (
              'translate(' +
              xScale(moment(di.date)) +
              ',' +
              yScale(di.volume) +
              ')'
            )
          }
        })

        updateTooltipContent(event, finalLineData)
        tooltip
          .style(
            'top',
            `${
              getTopPosition(event) // event.pageY - tooltip.node().getBoundingClientRect().height - 10
            }px`
          )
          .style(
            'left',
            `${
              getLeftPosition(event) // event.pageX - tooltip.node().getBoundingClientRect().width / 2
            }px`
          )
      }

      function mouseMoveQuarter (event) {
        const xco = event.offsetX
        d3.selectAll('.mouse-per-line').attr('transform', function (d) {
          const idx = getIdx(xco, d)
          const d0 = d.values[idx] // || d.values[idx]
          const d1 = d.values[idx + 1]
          let di = []
          if (d0 && d1) {
            di = xco - x('Q' + (moment(d0.date).format('M \'YY'))) > x('Q' + (moment(d1.date).format('M \'YY'))) - xco
              ? d1
              : d0
          } else {
            di = d1 || d0
          }
          d3.select('.mouse-line').attr('d', function () {
            let data =
              'M' +
              x('Q' + moment(di.date).format('M \'YY')) +
              ',' +
              (height - marginGeneral.bottom)
            data += ' ' + x('Q' + moment(di.date).format('M \'YY')) + ',' + marginGeneral.top
            return data
          })
          // return (
          //   'translate(' +
          //   x('Q' + moment(di.date).format('M \'YY')) +
          //   ',' +
          //   yScale(di.volume) +
          //   ')'
          // )
        })

        updateTooltipContentQuarter(event, finalLineData)
        tooltip
          .style(
            'top',
            `${
              getTopPosition(event) // event.pageY - tooltip.node().getBoundingClientRect().height - 10
            }px`
          )
          .style(
            'left',
            `${
              getLeftPosition(event) // event.pageX - tooltip.node().getBoundingClientRect().width / 2
            }px`
          )
      }

      const mousePerLine = mouseG
        .selectAll('.mouse-per-line')
        .data(finalLineData)
        .enter()
        .append('g')
        .attr('class', 'mouse-per-line')

      mousePerLine
        .append('circle')
        .attr('r', 4)
        .style('stroke', 'gray')
        .style('fill', 'none')
        .style('stroke-width', '1px')
        .style('opacity', '0')

      function getIdx (xco, d) {
        if (ActiveTimePeriod === 'month') {
          const xDate = xScale.invert(xco) // use 'invert' to get date corresponding to distance from mouse position relative to svg
          const bisect = d3.bisector(function (d) {
            return moment(d.date)
          }).left // retrieve row index of date on parsed csv
          const idx = bisect(d.values, xDate)
          return idx
        } else {
          const domain = x.domain()
          const range = x.range()
          const rangePoints = d3.range(range[0], range[1], x.step())
          const yPos = domain[d3.bisect(rangePoints, xco) - 1]
          const xDate = moment(yPos, 'M \'YY')
          const bisect = d3.bisector(function (d) {
            return d.date // x('Q' + moment(d.date).format('M \'YY'))
          }).left // retrieve row index of date on parsed csv
          const idx = bisect(d.values, xDate)
          return idx
        }
      }

      mouseG
        .append('svg:rect') // append a rect to catch mouse movements on canvas
        .attr('transform', `translate(${marginGeneral.left - 20},0)`)
        .attr('width', width - marginGeneral.left + 20) // can't catch mouse events on a g element
        .attr('height', height - marginGeneral.bottom)
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .on('mouseout', function () {
          // on mouse out hide line, circles and text
          d3.select('.mouse-line').style('opacity', '0')
          d3.selectAll('.mouse-per-line text').style('opacity', '0')
          tooltip.style('display', 'none')
        })
        .on('mouseover', function () {
          // on mouse in show line, circles and text
          d3.select('.mouse-line').style('opacity', '1')
          tooltip.style('display', 'inline')
        })
        .on('mousemove', (d) => {
          if (ActiveTimePeriod === 'month') {
            mouseMoveMonthly(event)
          } else {
            mouseMoveQuarter(event)
          }
        })

      function updateTooltipContentQuarter (event, resNested) {
        const xco = event.offsetX
        let sortingObj = []
        let idx = 0
        // eslint-disable-next-line array-callback-return
        resNested.map((d) => {
          idx = getIdx(xco, d)
          const d0 = d.values[idx] // || d.values[idx]
          const d1 = d.values[idx + 1]
          let di = {}
          if (d0 && d1) {
            di = xco - x('Q' + moment(d0.date).format('M \'YY')) > x('Q' + moment(d1.date).format('M \'YY')) - xco
              ? d1
              : d0
          } else {
            di = d1 || d0
          }
          sortingObj.push({
            key: di[`${item}_name`],
            volume: di.volume,
            date: di.date
          })
        })

        let month = ''
        sortingObj.forEach((p) => {
          month =
            ActiveTimePeriod === 'quarter'
              ? 'Q' + (moment(p.date).month() + 1) + ' ' + moment(moment(p.date).year(), 'YYYY').format('\'YY')
              : moment(p.date, 'YYYY-MM-DD').format("MMM 'YY")
        })

        sortingObj = sortingObj.sort(function (p, q) {
          return d3.descending(p.volume, q.volume)
        })

        const sortingArr = sortingObj.map((d) => d.key)
        const resNested1 = resNested.slice().sort(function (a, b) {
          return sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name)
        })

        tooltip
          .html(function (d) {
            return `<div style="width:100%; border-bottom: 1px solid gray">${month}</div><div style="width:100%; display:flex;"><div style="width:60%;">${currentModule === 'voc' ? 'Product' : 'Company'}</div><div style="width:40%;">Review volume</div></div>`
          })
          .style('display', 'inline')
        tooltip
          .selectAll()
          .data(resNested1)
          .enter() // for each vehicle category, list out name and price of premium
          .append('div')
          .style('color', (d) => {
            return 'white'
          })
          .style('font-size', 10)
          .html((d) => {
            const xco = event.offsetX
            const idx = getIdx(xco, d)
            const d0 = d.values[idx - 1] // || d.values[idx]
            const d1 = d.values[idx]
            let di = {}
            if (d0 && d1) {
              di = xco - x('Q' + moment(d0.date).format('M \'YY')) > x('Q' + moment(d1.date).format('M \'YY')) - xco
                ? d1
                : d0
            } else {
              di = d1 || d0
            }
            if (di) {
              return `<div style="width:100%; display:flex;"><div class="tip-${item}_name" style="width:60%;">${di[`${item}_name`]}</div> <div style="width:40%; text-align: center">${di.volume}</div></div>`
            } else {
              const tooltipHtml = document.querySelector('.mltooltip')
              tooltipHtml.style.display = 'none'
            }
          })
      }

      function updateTooltipContent (event, resNested) {
        const xco = event.offsetX
        let sortingObj = []
        // eslint-disable-next-line array-callback-return
        resNested.map((d) => {
          const xDate = xScale.invert(xco)
          const bisect = d3.bisector(function (d) {
            return moment(d.date)
          }).left
          const idx = bisect(d.values, xDate)
          const d0 = d.values[idx - 1] // || d.values[idx]
          const d1 = d.values[idx]
          let di = {}
          if (d0 && d1) {
            di =
              xco - xScale(moment(d0.date)) > xScale(moment(d1.date)) - xco
                ? d1
                : d0
          } else {
            di = d1
          }
          if (di) {
            sortingObj.push({
              key: di[`${item}_name`],
              volume: di.volume,
              date: di.date
            })
          }
        })
        let month = ''
        sortingObj.forEach((x) => {
          month =
            ActiveTimePeriod === 'quarter'
              ? 'Q' + (x.date.getMonth() + 1) + ' ' + moment(x.date.getFullYear(), 'YYYY').format('\'YY')
              : moment(x.date, 'YYYY-MM-DD').format("MMM 'YY")
        })

        sortingObj = sortingObj.sort(function (x, y) {
          return d3.descending(x.volume, y.volume)
        })

        const sortingArr = sortingObj.map((d) => d.key)
        const resNested1 = resNested.slice().sort(function (a, b) {
          return sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name) // rank vehicle category based on price of premium
        })

        tooltip
          .html(function (d) {
            return `<div style="width:100%; border-bottom: 1px solid gray">${month}</div><div style="width:100%; display:flex;"><div style="width:60%;">${currentModule === 'voc' ? 'Product' : 'Company'}</div><div style="width:40%;">Review volume</div></div>`
          })
          .style('display', 'inline')
        tooltip
          .selectAll()
          .data(resNested1)
          .enter() // for each vehicle category, list out name and price of premium
          .append('div')
          .style('color', (d) => {
            return 'white'
          })
          .style('font-size', 10)
          .html((d) => {
            const xco = event.offsetX
            const xDate = xScale.invert(xco)
            const bisect = d3.bisector(function (d) {
              return moment(d.date)
            }).left
            const idx = bisect(d.values, xDate)
            const d0 = d.values[idx - 1] || d.values[idx]
            const d1 = d.values[idx]
            let di = {}
            if (d0 && d1) {
              di = xco - xScale(moment(d0.date)) > xScale(moment(d1.date)) - xco
                ? d1
                : d0
            } else {
              di = d1
            }
            if (di) {
              return `<div style="width:100%; display:flex;"><div class="tip-${item}_name" style="width:60%;">${di[`${item}_name`]}</div> <div style="width:40%; text-align: center">${di.volume}</div></div>`
            } else {
              const tooltipHtml = document.querySelector('.mltooltip')
              tooltipHtml.style.display = 'none'
            }
          })
      }
      let totalWidth = 0
      svg.select('.x-axis')
        .selectAll('g.tick')
        .each(function (d, index, data) {
          const temp = d3
            .select(this)
            .select('text')
            .node()
            .getBoundingClientRect().width
          totalWidth += temp
        })
      if (totalWidth + marginGeneral.right + marginGeneral.left > width) {
        svg.select('.x-axis')
          .selectAll('g.tick')
          .each(function (d, index, data) {
            if (!(index === 0 || index === data.length - 1)) {
              d3.select(this).select('text').remove()
            }
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inner-chart-block {
      display: block;
      width: 100%;
      height: calc(100% - 36px);
}
</style>
