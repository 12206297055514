var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-main"},[_c('ReportWithSliderVue',{attrs:{"numReports":_vm.numReports}},[_c('div',{class:[
        'report-donut-chart width-half',
        _vm.reportFullscreen.status ? 'width-full' : 'padding-right'
      ]},[_c('div',{staticClass:"report-inside"},[_c('ReportHorizontalVue',{attrs:{"noData":_vm.noData,"item":_vm.item}})],1)]),_c('div',{class:[
        'report-donut-chart width-half',
        _vm.reportFullscreen.status ? 'width-full' : ''
      ]},[_c('div',{staticClass:"report-inside"},[_c('ReviewsOverTime',{attrs:{"noData":_vm.noData,"item":_vm.item}})],1)]),_c('div',{class:[
        'report-donut-chart',
        _vm.reportFullscreen.status ? 'width-full' : 'padding-right', _vm.currentModule === 'voc' ? 'width-half' : 'width-full'
      ]},[_c('div',{staticClass:"report-inside"},[_c('ReportVerticalVue',{attrs:{"title":"Rating over time","overallScore":_vm.overallAvgCsat,"id":3,"stack":_vm.stack,"max":5,"type":"csat_rating","chartId":"rating-over-time","noData":_vm.noData,"item":_vm.item}})],1)]),(_vm.currentModule ==='voc')?_c('div',{class:[
        'report-donut-chart width-half',
        _vm.reportFullscreen.status ? 'width-full' : ''
      ]},[_c('div',{staticClass:"report-inside"},[_c('ReportVerticalVue',{attrs:{"title":"Recommendation % over time","overallScore":_vm.overallRecommendationScore,"id":4,"stack":_vm.stack,"min":0,"max":100,"type":"recommendation_score","chartId":"recommendation-over-time","noData":_vm.noData,"item":_vm.item}})],1)]):_vm._e(),(_vm.currentModule === 'voc')?_c('div',{class:[
        'report-donut-chart width-full'
      ]},[_c('div',{staticClass:"report-inside"},[_c('bubble-chart-vue',{attrs:{"noData":_vm.noData,"data":_vm.compareRatingOverRecommendation,"legendsText":_vm.bubbleLegends}})],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }