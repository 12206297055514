<template>
  <div ref="horizontalChart" :class="['horizontal-container', reportFullscreen.status?'change-height':'', isCollapsed & reportFullscreen.status ? 'change-width': '']">
    <!-- <div class="donut-block" v-if="!noData && !reportFullscreen.status">
      <p>Review volume</p>
      <div class="donut-center">
        <div class="total-review-circle">
          <div class="review-count">
            <div class="count">{{ totalReview }}</div>
            <div>Reviews</div>
          </div>
        </div>
      </div>
    </div> -->
    <div v-if="!noData" :class="['chart-block', reportFullscreen.status?'full-width':'']">
      <div class="chart-block-header">
        <GroupBySelector />
        <b-button class="toggle-button" @click="toggleReportFullscreen(id)">
          <svg xmlns="http://www.w3.org/2000/svg" class="expand-icon" width="16" height="16" fill="#c2c7de" viewBox="0 0 16 16" v-if="!fullscreen" v-bind:class="'icons'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.328 10.181H0V16h5.819v-2.328H2.328zM0 5.819h2.328V2.328h3.491V0H0zM10.181 0v2.328h3.491v3.491H16V0zM13.672 13.672h-3.491V16H16v-5.819h-2.328z" class="cls-1"/></svg>
          <svg xmlns="http://www.w3.org/2000/svg" fill="#c2c7de" width="16" height="16" viewBox="0 0 16 16" v-else="" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.491 16h2.328v-5.819H0v2.328h3.491zM5.819 0H3.491v3.491H0v2.328h5.819zM16 5.819V3.491h-3.491V0h-2.328v5.819zM12.509 12.509H16v-2.328h-5.819V16h2.328z" class="cls-1"/></svg>
        </b-button>
      </div>
      <div class="chart-container-horizontal">
        <svg id="charts-horizontal"></svg>
      </div>
    </div>
    <no-data-container-vue v-if="noData" />
  </div>
</template>

<script>
import * as d3 from 'd3'
import { mapState, mapActions } from 'vuex'
import tooltip from '../../util/tooltip'
import GroupBySelector from '@/components/Report/GroupBySelector.vue'
import { itemColors } from '@/util/util.js'
import NoDataContainerVue from '../Common/NoDataContainer/NoDataContainer.vue'
import { fullScreenMode, numberFormatter } from '../../util/util'
/**
 * This component renders a chart that shows the number of reviews and their average ratings across different criteria. *(Products, Sources, Industries and Company Sizes)*
 */
export default {
  name: 'ReportHorizontal',
  components: {
    GroupBySelector,
    NoDataContainerVue
  },
  props: {
    /** This prop indicates whether or not the component has the minimum data required to render the chart */
    noData: {
      type: Boolean
    },
    /** This prop indicates what item the reviews are for. Eg - Product, Company
     * @values 'product', 'company'
     */
    item: {
      type: String
    }
  },
  data () {
    return {
      data: [],
      selected: '',
      id: 1,
      observer: null
    }
  },
  computed: {
    ...mapState('report', ['overall', 'groupBy']),
    ...mapState('filters', [
      'selectedProducts',
      'selectedSourceList',
      'selectedIndustries',
      'selectedCompanies',
      'selectedCompaniesVoe'
    ]),
    ...mapState('common', ['isCollapsed', 'reportFullscreen', 'currentModule']),
    fullscreen () {
      return (this.reportFullscreen.id === this.id && this.reportFullscreen.status)
    },
    totalReview () {
      return this.overall.reduce((a, b) => a + (Number(b.review_volume) || 0), 0)
    }
  },
  watch: {
    overall () {
      if (this.overall.length) { this.renderChart() }
    }
  },
  mounted () {
    this.resizeWindow()
    if (this.overall.length) { this.renderChart() }
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    ...mapActions('report', ['updateGroupBy', 'getGroupedReport']),
    ...mapActions('common', ['toggleReportFullscreen', 'resetReportFullscreen']),
    ...mapActions('filters', ['updateSelectedProducts', 'updateSelectedSources', 'updateSelectedIndustries', 'updateSelectedCompanies', 'updateSelectedCompaniesVoe']),
    numberFormatter,
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (this.overall.length) {
          if (d3.select('.chart-container-horizontal').node() != null) { this.renderChart() }
        }
      })
      this.observer.observe(this.$refs.horizontalChart)
    },
    /**
     * This method programmatically applies the global filters displayed by the chart and redirects the user to the *Comments* page to view those comments.
     * @param d {String} The value used to signify the criteria according to which the reviews are being grouped by
     * @public
     */
    onClickForwardData (d) {
      let data = []
      if (this.reportFullscreen.status) {
        this.resetReportFullscreen()
        fullScreenMode(document, 'off')
      }
      switch (this.groupBy.id) {
        case 'product':
          data = this.selectedProducts.filter(({ name }) => name === d[`${this.item}_name`])
          if (data.length) {
            this.updateSelectedProducts([data[0]])
          }
          break
        case 'source':
          data = this.selectedSourceList.filter(({ name }) => name === d[`${this.item}_name`])
          if (data.length) {
            this.updateSelectedSources({ sources: [data[0]] })
          }
          break
        case 'industry':
          data = this.selectedIndustries.filter(({ name }) => name === d[`${this.item}_name`])
          if (data.length) {
            this.updateSelectedIndustries([data[0]])
          }
          break
        case 'company_size':
          data = this.selectedCompanies.filter(({ name }) => name === d[`${this.item}_name`])
          if (data.length) {
            this.updateSelectedCompanies([data[0]])
          }
          break
      }
      if (this.currentModule === 'voc') {
        this.$router.push({ name: 'comments' })
      } else if (this.currentModule === 'voe') {
        data = this.selectedCompaniesVoe.filter(({ name }) => name === d[`${this.item}_name`])
        if (data.length) {
          this.updateSelectedCompaniesVoe([data[0]])
        }
        this.$router.push({ name: 'voeComments', path: 'comments' })
      }
      this.tooltipFun(event, d, 'out')
    },
    /** Returns the name of the criteria by which reviews are being grouped by, to display in the tooltip.
    * @param value {String} The value used to signify the criteria according to which the reviews are being grouped by
    * @public
    */
    getProductName (value) {
      switch (value) {
        case 'product':
          return 'Product'
        case 'source':
          return 'Source'
        case 'industry':
          return 'Industry'
        case 'company_size':
          return 'Company size'
      }
    },
    tooltipFun (event, d, type) {
      let data = {}
      switch (type) {
        case 'in':
          if (this.currentModule === 'voc') {
            data = {
              [this.getProductName(this.groupBy.id)]: d[`${this.item}_name`],
              'Review volume': d.review_volume,
              'Avg. rating': Number(d.csat_ratings).toFixed(1)
            }
          } else if (this.currentModule === 'voe') {
            data = {
              'Company': d[`${this.item}_name`],
              'Review volume': d.review_volume,
              'Avg. rating': Number(d.csat_ratings).toFixed(1)
            }
          }
          break
      }
      tooltip(event, data, type)
    },
    /** This method renders a horizontal bar graph */
    renderChart () {
      d3.selectAll('#charts-horizontal > *').remove()
      const margin = {
        top: 0,
        right: 50,
        bottom: 0,
        left: 150
      }
      const width = d3.select('.chart-container-horizontal').node().getBoundingClientRect()
        .width
      const height = d3
        .select('.chart-container-horizontal')
        .node()
        .getBoundingClientRect().height
      const svg = d3
        .select('#charts-horizontal')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      const x = d3
        .scaleLinear()
        .domain([
          0,
          d3.max(this.overall, function (d) {
            return Number(d.review_volume)
          })
        ])
        .range([0, width - margin.left - margin.right])

      svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x))
        .attr('class', 'xaxis')
        .select('path')
        .remove()
        .selectAll('text')
        .attr('transform', 'translate(-10,0) rotate(-45)')
        .style('text-anchor', 'end')

      d3.selectAll('.xaxis .tick').remove()

      const y = d3
        .scaleBand()
        .range([margin.top, height - margin.bottom])
        .domain(
          this.overall.map((d) => {
            return d[`${this.item}_name`]
          })
        )
        .padding(0.2)
      svg
        .append('g')
        .call(d3.axisLeft(y))
        .attr('font-size', '12px')
        .select('path')
        .remove()

      d3.select('#charts-horizontal')
        .selectAll('.tick text')
        .call(wrap, 120, y.bandwidth())

      d3.select('#charts-horizontal')
        .selectAll('.tick')
        .select('line')
        .remove()

      const bar = svg
        .selectAll('.myRect')
        .data(this.overall)
        .enter()
        .append('g')

      bar
        .append('rect')
        .attr('x', x(0))
        .attr('y', (d) => {
          return y(d[`${this.item}_name`])
        })
        .attr('width', function (d) {
          return x(d.review_volume)
        })
        .attr('height', y.bandwidth())
        .style('fill', (d, index) => {
          if (d[`${this.item}_name`] === 'Others') {
            return 'gray'
          }
          if (this.currentModule === 'voc') {
            if (this.groupBy.id === 'product') {
              return itemColors(
                this.selectedProducts
                  .map((data) => data.name)
                  .indexOf(d[`${this.item}_name`])
              )
            }
            return itemColors(this.selectedProducts.length + index)
          } else if (this.currentModule === 'voe') {
            return itemColors(
              this.selectedCompaniesVoe
                .map((data) => { return data.name })
                .indexOf(d[`${this.item}_name`])
            )
          }
        })
        .attr('cursor', 'pointer')
        .on('mouseover', (event, d) => this.tooltipFun(event, d, 'in'))
        .on('mousemove', (event, d) => this.tooltipFun(event, d, 'in'))
        .on('mouseout', (event, d) => this.tooltipFun(event, d, 'out'))
        .on('click', (event, d) => this.onClickForwardData(d))
      d3
        .select('.flex-seven')
        .on('scroll', () => d3.selectAll('.tooltip').style('display', 'none'))

      bar
        .append('text')
        .attr('class', 'hlabel')
        .attr('pointer-events', 'none')
        .attr('y', (d) => {
          return y(d[`${this.item}_name`]) + y.bandwidth() / 2
        })
        .attr('dy', '.35em')
        .attr('dx', function (d) {
          return x(d.review_volume) - 10
        })
        .attr('text-anchor', 'end')
        .text(function (d) {
          return d.review_volume // x(d.review_volume) > 20 ? d.review_volume : ''
        })
        .attr('fill', '#ffffff')
        .attr('stroke', 'none')
        .attr('font-size', '12px')

      bar
        .append('text')
        .attr('fill', 'var(--secondary-text-color)')
        .attr('stroke', 'none')
        .attr('font-size', '12px')
        .attr('x', function (d) {
          return x(d.review_volume) + 10
        })
        .attr('y', (d) => {
          return y(d[`${this.item}_name`]) + y.bandwidth() / 2
        })
        .attr('dy', '.35em')
        .text(function (d) {
          return Number(d.csat_ratings).toFixed(1)
        })

      function wrap (text, width, bandwidth) {
        text.each(function () {
          const textw = d3.select(this)
          const words = textw.text().split(/\s+/).reverse()
          let word
          let line = []
          let lineNumber = 0
          const lineHeight = 1.1 // ems
          const y = textw.attr('y') // - bandwidth/2,
          const dy = parseFloat(textw.attr('dy'))
          let tspan = textw
            .text(null)
            .attr('fill', 'var(--reports-label-color)')
            .attr('id', 'labels')
            .style('font-family', 'Quicksand')
            .attr('font-size', 12)
            .append('tspan')
            .attr('x', -4)
            .attr('y', y - lineHeight)
            .attr('dy', dy + 'em')
          while ((word = words.pop())) {
            line.push(word)
            tspan.text(line.join(' '))
            if (tspan.node().getBBox().width > width && textw.node().getBBox().height < bandwidth) {
              line.pop()
              tspan.text(line.join(' '))
              line = [word]
              tspan = textw
                .append('tspan')
                .attr('x', -4)
                .attr('y', y - lineHeight)
                .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                .text(word)
            }
            // this is for last line whose width will be longer and cannot be wrap in anymore
            if (textw.node().getBBox().height > bandwidth && tspan.node().getBBox().width > width) {
              let lastLine = tspan.text()
              let i = 1
              while (tspan.node().getBBox().width > width && lastLine.length > 0) {
                lastLine = tspan.text().slice(0, -i)
                tspan.text(lastLine + '...')
                i++
              }
              break
            }
          }
        })
      }
      const position = [0, 5, 12, 20]
      d3.selectAll('#labels').each(function (d) {
        let count = 0
        d3.select(this).selectAll('tspan').each(function (d) {
          ++count
        })
        d3.select(this).attr('transform', `translate(0,-${position[count - 1] ? position[count - 1] : 0})`)
      })
      bar.each(function (d) {
        const textWidth = d3.select(this).select('text.hlabel').node().getBoundingClientRect().width
        const barWidth = d3.select(this).node().getBoundingClientRect().width
        if ((textWidth + 40) > barWidth) {
          d3.select(this).select('text.hlabel').remove()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.horizontal-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  height: 40vh;
  min-height: 300px;
  transition: width 0.3s ease-out;
  .donut-block {
    display: flex;
    flex-direction: column;
    width: 260px;
    border-right: solid 2px var(--c-sat-driver-table-border);
    height: 100%;
    padding: 10px 0px 10px 20px;
    p {
      margin: 0px;
      font-size: 18px;
      color: var(--secondary-text-color);
      height: 36px;
      display: flex;
      align-items: center;
      font-family: Quicksand;
    }
  }
  .chart-block {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px 20px 10px 20px;
    .chart-block-header {
      display: flex;
      height: 36px;
      align-items: center;
      font-weight: 500;
    }
    .toggle-button {
      margin-left: auto;
      background-color: transparent;
      outline: none;
      border: none;
      width: 35px;
      height: 35px;
      display: flex;
      svg {
        display: flex;
      }
    }
    width: calc(100% - 260px);
  }
  .full-width {
    width: 100%;
  }
  .chart-container-horizontal {
    height: calc(100% - 36px);
    width: 100%;
  }
  .header {
    opacity: 1;
    font-size: 18px;
    width: 100%;
    margin-bottom: 5px;
          font-family: Quicksand;
  }
  .donut-center {
    display: flex;
    flex: 1;
    padding-left: 20px;
    align-items: center;
    .total-review-circle {
      width: 180px;
      height: 180px;
      text-align: center;
      align-self: center;
      border-radius: 50%;
      border: 15px solid #2780eb;
      display: flex;
      margin-top: 10px;
      .review-count {
        text-align: center;
        align-self: center;
        width: 100%;
        font-family: Quicksand;
        color: var(--secondary-text-color);
        .count {
          font-size: 44px;
          font-weight: bold;
          line-height: 50px;
        }
      }
    }
  }

  .group-filter {
    margin-left: 40px;
    font-size: 18px;
    align-self: center;
    ul li label {
      margin: 0px;
      font-size: 12px;
    }
    ul {
      list-style-type: none;
      padding-inline-start: 0px;
    }
    .radio-checkmark {
      position: absolute;
      top: 6px;
      left: 0;
      height: 15px;
      border-radius: 50%;
      width: 15px;
      background-color: white;
      border: 1px solid #dadada;
    }
    .custom-radio input:checked ~ .radio-checkmark {
      background-color: #5aa4ee;
    }
    .custom-radio {
      display: block;
      position: relative;
      padding-left: 20px;
      cursor: pointer;
      font-size: 16px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}
.change-height {
  height: calc(100vh - 15px - var(--page-tracking-height));
  width: calc(100vw - 250px);
  }
.change-width {
  width: calc(100vw - 100px)
}

@media screen and (min-width: 1700px) {
  .change-height {
    width: calc(100vw - 285px);
    }
  .change-width {
    width: calc(100vw - 120px)
  }
}
</style>
