<template>
  <div ref="csatOverTime" :class="['report-csat-over-time-container rv',reportFullscreen.status?'change-height':'', isCollapsed & this.reportFullscreen.status ? 'change-width': '']">
    <div v-if="!noData" class="header">
      {{title}}
      <b-button class="toggle-button" @click="toggleReportFullscreen(id)">
        <div>
        <svg xmlns="http://www.w3.org/2000/svg" class="expand-icon" width="16" height="16" fill="#c2c7de" viewBox="0 0 16 16" v-if="!fullscreen" v-bind:class="'icons'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.328 10.181H0V16h5.819v-2.328H2.328zM0 5.819h2.328V2.328h3.491V0H0zM10.181 0v2.328h3.491v3.491H16V0zM13.672 13.672h-3.491V16H16v-5.819h-2.328z" class="cls-1"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="#c2c7de" width="16" height="16" viewBox="0 0 16 16" v-else="" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.491 16h2.328v-5.819H0v2.328h3.491zM5.819 0H3.491v3.491H0v2.328h5.819zM16 5.819V3.491h-3.491V0h-2.328v5.819zM12.509 12.509H16v-2.328h-5.819V16h2.328z" class="cls-1"/></svg>
        </div>
      </b-button>
    </div>
    <div v-if="!noData" :class="[`chart-container ${chartId}-chart`]">
      <svg :id="chartId"></svg>
    </div>
    <no-data-container-vue v-if="noData" />
  </div>
</template>

<script>
import * as d3 from 'd3'
import moment from 'moment'
import tooltip from '../../util/tooltip'
import { mapState, mapActions } from 'vuex'
import { itemColors } from '@/util/util.js'
import NoDataContainerVue from '../Common/NoDataContainer/NoDataContainer.vue'
import { fullScreenMode } from '../../util/util'

export default {
  name: 'ReportVertical',
  components: {
    NoDataContainerVue
  },
  // props: ['id', 'noData', 'chartId', 'stack', 'type', 'max', 'min', 'overallScore', 'title'],
  props: {
    /** This prop provides the context for which report is currently in fullscreen mode */
    id: Number,
    /** This prop indicates whether the minimum data required to render the chart is available or not */
    noData: Boolean,
    /** This prop contains the id of the actual chart element */
    chartId: String,
    /** This prop contains the data required to render the chart. *(Monthwise or Quarter-wise distribution of ratings over time)* */
    stack: Array,
    /** This prop identifies which type of chart is being renderd
     * @value recommendation_score, csat_rating
     */
    type: String,
    /** Used to set the maximum value of the y-scale domain */
    max: Number,
    /** Used to set the minimum value of the y-scale domain */
    min: Number,
    /** This prop contains the value of the avg CSAT rating. It's used to draw the horizontal dashed line in the chart */
    overallScore: Number,
    /** This prop contains the text that is displayed as the header for the chart */
    title: String,
    item: String
  },
  data () {
    return {
      observer: null
    }
  },
  computed: {
    ...mapState('report', ['sortingArray']),
    ...mapState('filters', [
      'selectedCategories',
      'selectedProducts',
      'ActiveVocTimePeriod',
      'ActiveVoeTimePeriod',
      'selectedCompaniesVoe',
      'categoryList'
    ]),
    ...mapState('common', ['isCollapsed', 'reportFullscreen', 'currentModule']),
    fullscreen () {
      return (this.reportFullscreen.id === this.id && this.reportFullscreen.status)
    },
    ActiveTimePeriod () {
      if (this.currentModule === 'voc') {
        return this.ActiveVocTimePeriod
      } else {
        return this.ActiveVoeTimePeriod
      }
    }
  },
  watch: {
    stack () {
      if (this.stack.length) {
        this.renderChart()
      }
    }
  },
  mounted () {
    if (this.stack.length) {
      this.renderChart()
    }
    this.resizeWindow()
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    ...mapActions('filters', [
      'updateSelectedProducts',
      'updateActiveVocTimePeriod',
      'updateActiveVoeTimePeriod'
    ]),
    ...mapActions('common', ['toggleReportFullscreen', 'resetReportFullscreen']),
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (this.stack.length) {
          if (d3.select(`.${this.chartId}-chart`).node() != null) {
            this.renderChart()
          }
        }
      })
      this.observer.observe(this.$refs.csatOverTime)
    },
    /**
     * This method renders the chart
     * @public
     */
    renderChart () {
      const overallAvgCsat = this.overallScore
      const category = this.selectedCategories
      const categoryList = this.categoryList
      const sortingArray = this.sortingArray
      const ActiveTimePeriod = this.ActiveTimePeriod.group_by
      const timeRange = this.ActiveTimePeriod.range
      const chartType = this.type
      const startDate = moment(timeRange[0], 'YYYY-MM-DD')
      const endDate = moment(timeRange[1], 'YYYY-MM-DD')
      const currentModule = this.currentModule
      function listQuarters (sDate, eDate) {
        let quarterList = []
        let q = sDate.quarter()
        while (sDate.isBefore(eDate)) {
          q = sDate.quarter()
          quarterList.push('Q' + q + moment(sDate.year(), 'YYYY').format(' \'YY'))
          sDate.add(1, 'month')
        }
        quarterList = [...new Set(quarterList)]
        return quarterList
      }
      function getSubgroups () {
        let result = []
        if (ActiveTimePeriod === 'month') {
          while (startDate.isBefore(endDate)) {
            result.push(startDate.format('MMM \'YY'))
            startDate.add(1, 'month')
          }
        } else {
          result = listQuarters(startDate, endDate)
        }
        return result
      }
      d3.selectAll(`#${this.chartId} > *`).remove()
      const margin = {
        top: 30,
        right: 40,
        bottom: 32,
        left: 20
      }
      const width = d3.select(`.${this.chartId}-chart`).node().getBoundingClientRect().width
      const height =
        d3.select(`.${this.chartId}-chart`).node().getBoundingClientRect().height
      const svg = d3
        .select(`#${this.chartId}`)
        .attr('width', width)
        .attr('height', height)
      svg.append('g')

      const items = [
        ...new Set(this.stack.map((item) => item[`${this.item}_name`]))
      ]
      const dates = []
      for (const data of this.stack) {
        dates.push(
          moment(`${data.month}-${data.year_of_review}`, 'M-YYYY').format('YYYY-MM-DD')
        )
      }
      dates.sort()
      const subgroups = getSubgroups()
      const finalData = []
      for (const item of items) {
        const object = {}
        object.group = item
        for (const check of this.stack) {
          if (check[`${this.item}_name`] === item) {
            object[
              ActiveTimePeriod === 'quarter'
                ? 'Q' + check.month + ' ' + moment(check.year_of_review, 'YYYY').format('\'YY')
                : moment(
                  `${check.month}-${check.year_of_review}`,
                  'M-YYYY'
                ).format('MMM \'YY')
            ] = check[this.type]
          }
        }
        finalData.push(object)
      }

      items.sort(function (a, b) {
        return sortingArray.indexOf(a) - sortingArray.indexOf(b)
      })
      const x = d3
        .scaleBand()
        .domain(items)
        .rangeRound([margin.left, width])
        .padding([0.1])
      svg
        .append('g')
        .attr('transform', 'translate(0,' + (height - margin.bottom + 11) + ')')
        .call(d3.axisBottom(x))
        .attr('id', 'parent-x-axis-labels')
        .selectAll('.tick text')
        .call(wrap, x.bandwidth() * 0.8)
        .select('path')
        .style('display', 'none')
      svg
        .selectAll('.tick')
        .attr('class', `x-${this.item}`)
        .select('line')
        .style('display', 'none')
      svg
        .selectAll(`.x-${this.item}`)
        .select('text')
        .attr('fill', 'var(--reports-label-color)')
        .attr('font-family', 'Quicksand, sans-serif')
        .attr('font-size', '13px')

      const xSubgroup = d3
        .scaleBand()
        .domain(subgroups)
        .range([0, x.bandwidth() - 10])
        .padding([0.2])

      const xAxis = d3.axisBottom().scale(xSubgroup)
      svg
        .append('g')
        .attr('transform', 'translate(0,5)')
        .selectAll('g')
        .data(finalData)
        .enter()
        .append('g')
        .attr('transform', function (d) {
          return `translate( ${x(d.group)}, ${height - margin.bottom - 7})`
        })
        .call(xAxis)
        .attr('class', 'x-ticks')
        .select('path')
        .style('display', 'none')

      if (
        svg
          .selectAll('.x-ticks')
          .selectAll('.tick')
          .select('text')
          .node()
          .getBoundingClientRect().width > xSubgroup.bandwidth()
      ) {
        // console.log(svg.select('#parent-x-axis-labels'), 'ji')
        // margin.bottom = margin.bottom - 10
        svg.select('#parent-x-axis-labels').attr(
          'transform',
          'translate(0,' + (height - margin.bottom) + ')'
        )
        svg
          .selectAll('.x-ticks')
          .selectAll('.tick')
          .select('text')
          .call(dedupe, xSubgroup.bandwidth())
      }
      // console.log(finalData, 'finalData')

      function dedupe (text, width) {
        text.each(function () {
          const text = d3.select(this)
          text.attr('opacity', 0)
        })
      }

      let min = this.min || d3.min(this.stack, (d) => {
        return Number(d[this.type])
      })
      const max = this.max
      // console.log('min, max', 'min')
      min = Math.floor(min) < min ? Math.floor(min) : min > 0 ? min - 1 : min
      const y = d3
        .scaleLinear()
        .domain([min, max])
        .range([height - margin.bottom, margin.left])
        .nice()
      svg
        .append('g')
        .attr('transform', 'translate(' + (margin.left + 4) + ',0)')
        .call(
          d3
            .axisLeft(y)
            .ticks(6)
            .tickSize(-(width - margin.right))
        )
        // .attr('stroke-opacity', 0.2)
        // .attr('stroke', '#e4e7eb')
        .attr('class', 'big-ticks')
        .select('path')

      svg.selectAll('.tick').attr('class', 'grouped-yaxis') // .select("line").style("display", "none");
      svg
        .selectAll('.grouped-yaxis')
        .select('text')
        .attr('fill', 'var(--reports-label-color)')
        .attr('font-family', 'Quicksand, sans-serif')
        .attr('font-size', '12px')
        .attr('stroke', 'none')
        .attr('fill-opacity', '0.8')

      function makeYGridlines () {
        return d3.axisLeft(y).ticks(6)
      }

      svg.select('.big-ticks').selectAll('.grouped-yaxis').each(function (d, index) {
        if (index === 0) {
          d3.select(this)
            .select('line')
            .attr('stroke', 'var(--review-over-time-dotted-bottom-line-color)')
            .attr('stroke-width', 1)
        } else {
          d3.select(this)
            .select('line')
            .attr('stroke', 'var(--chart-background-dotted-line-color)')
            .attr('stroke-width', 1)
        }
      })
      svg
        .append('g')
        .attr('class', 'vgrid')
        .attr('transform', 'translate(' + (margin.left + 4) + ',0)')
        .call(
          makeYGridlines()
            .tickSize(-(width - margin.right))
            .tickFormat('')
        )
      // .attr('stroke-opacity', 0.2)
      // .attr('stroke', '#e4e7eb')

      svg.selectAll('.vgrid').select('.domain').style('display', 'none')
      svg.selectAll('.vgrid').selectAll('.tick').style('display', 'none')
      svg.select('.big-ticks').selectAll('.grouped-yaxis').each(function (d, index) {
        if (index === 0) {
          d3.select(this)
            .select('line')
            .attr('stroke', 'var(--review-over-time-dotted-bottom-line-color)')
        }
      })

      const drillDown = (event, d, type) => {
        if (this.reportFullscreen.status) {
          this.resetReportFullscreen()
          fullScreenMode(document, 'off')
        }
        const output = this.selectedProducts.filter(({ name }) => name === d.group)
        let range = []
        if (this.ActiveTimePeriod.group_by === 'quarter') {
          range = [moment(d.key, 'Q \'YY').startOf('quarter').format('YYYY-MM-DD'), moment(d.key, 'Q \'YY').endOf('quarter').format('YYYY-MM-DD')]
        } else {
          range = [moment(d.key, 'MMM \'YY').startOf('month').format('YYYY-MM-DD'), moment(d.key, 'MMM \'YY').endOf('month').format('YYYY-MM-DD')]
        }
        const timeObject = {}
        timeObject.group_by = this.ActiveTimePeriod.group_by
        timeObject.range = range
        if (this.currentModule === 'voc') {
          this.updateActiveVocTimePeriod(timeObject)
        } else if (this.currentModule === 'voe') {
          this.updateActiveVoeTimePeriod(timeObject)
        }
        if (output.length) {
          this.updateSelectedProducts([output[0]])
        }
        tooltipFun(event, d, 'out')
        if (currentModule === 'voc') {
          this.$router.push({ name: 'comments' })
        } else if (currentModule === 'voe') {
          this.$router.push({ name: 'voeComments', path: 'comments' })
        }
      }

      function tooltipFun (event, d, type, view) {
        const data = {}
        switch (type) {
          case 'in':
            if (view === 'bar') {
              data[''] = d.group
              const format =
                ActiveTimePeriod === 'quarter' ? 'Quarter' : 'Month'
              data[format] = d.key
            } else if (view === 'line') {
              data[''] = d.group
            } else if (view === 'dotted-line') {
              if (currentModule === 'voc') {
                data[''] = categoryList.find((obj) => obj.name === category.name).name
              } else if (currentModule === 'voe') {
                data[''] = 'Healthcare'
              }
            }
            data[view === 'dotted-line' ? `Overall Avg. ${chartType === 'csat_rating' ? 'rating' : 'recommendation'} ` : `Avg. ${chartType === 'csat_rating' ? 'rating' : 'recommendation'}`] =
              view === 'line'
                ? getAvg(d)
                : view === 'dotted-line'
                  ? Number(Number(overallAvgCsat).toFixed(1)) + (chartType !== 'csat_rating' ? '%' : '')
                  : (chartType !== 'csat_rating' ? Number(Number(+d.value).toFixed(1)) + '%' : Number(Number(+d.value).toFixed(1)))
            break
        }
        tooltip(event, data, type)
      }
      svg
        .append('g')
        .attr('id', 'vgparent')
        .selectAll('g')
        .data(finalData)
        .enter()
        .append('g')
        .attr('transform', function (d) {
          return 'translate(' + x(d.group) + ',0)'
        })
        .attr('class', 'vgroup')
        .selectAll('rect')
        .data(function (d) {
          return subgroups.map(function (key) {
            return {
              key: key,
              value: d[key] ? Number(+d[key]).toFixed(1) : 0,
              group: d.group
            }
          })
        })
        .enter()
        .append('g')
        .attr('class', 'vbar')
        .append('rect')
        .attr('x', function (d) {
          return xSubgroup(d.key)
        })
        .attr('y', (d) => {
          return y(this.type === 'csat_rating' ? d.value : d.value)
        })
        .attr('width', xSubgroup.bandwidth())
        .attr('height', (d) => {
          if (height - margin.bottom - y(d.value) >= 0) {
            return height - margin.bottom - y(d.value)
          }
        })
        .attr('fill', (d, index) => {
          if (this.currentModule === 'voc') {
            return itemColors(
              this.selectedProducts.map((data) => data.name).indexOf(d.group)
            )
          } else if (this.currentModule === 'voe') {
            return itemColors(
              this.selectedCompaniesVoe.map((data) => data.name).indexOf(d.group)
            )
          }
        }
        )
        .on('mouseover', (event, d) => tooltipFun(event, d, 'in', 'bar'))
        .on('mousemove', (event, d) => tooltipFun(event, d, 'in', 'bar'))
        .on('mouseout', (event, d) => tooltipFun(event, d, 'out', 'bar'))
        .on('click', (event, d) => drillDown(event, d, 'out', 'bar'))

      svg
        .selectAll('.x-ticks')
        .selectAll('.grouped-yaxis')
        .select('line')
        .style('display', 'none')
      svg
        .selectAll('.x-ticks')
        .selectAll('.tick')
        .select('text')
        .attr('fill', 'var(--reports-label-color)')
        .attr('font-size', 12)
        .attr('font-family', 'Quicksand, sans-serif')
        .attr('fill-opacity', '0.8')
      svg.selectAll('.domain').style('display', 'none')
      svg
        .selectAll('.inner-xaxis')
        .select('text')
        .attr('fill', 'var(--reports-label-color)')
        .attr('fill-opacity', '0.8')

      svg
        .selectAll('.vbar')
        .append('text')
        .attr('x', function (d) {
          return xSubgroup(d.key) + xSubgroup.bandwidth() / 2 - 8
        })
        .attr('y', function (d) {
          return height - margin.bottom - 10
        })
        .text(function (d) {
          return d.value
            ? xSubgroup.bandwidth() > 16 &&
              d3.select(this.parentNode).node().getBoundingClientRect().height >
              20
              ? Number(+d.value).toFixed(1)
              : null
            : null
        })
        .attr('fill', 'white')
        .attr('stroke', 'none')
        .attr('font-size', '12px')

      svg.selectAll('.vgroup')
        .append('line')
        .attr('x1', 0)
        .attr('x2', function (d, i) {
          return (svg.selectAll('.vgroup')._groups[0][i].getBBox().width !== 0)
            ? svg.selectAll('.vgroup')._groups[0][i].getBBox().width + 4
            : 0
        })
        .attr('y1', function (d) {
          let sum = 0
          let count = 0
          for (const e in d) {
            sum = sum + (e !== 'group' && typeof d[e] === 'number' ? d[e] : 0)
            count = count + (e !== 'group' && typeof d[e] === 'number' ? 1 : 0)
          }
          return (chartType !== 'csat_rating' ? y(Number(Number(sum / count).toFixed(1))) : y(Number(sum / count).toFixed(1)))
        })
        .attr('y2', function (d) {
          let sum = 0
          let count = 0
          for (const e in d) {
            sum = sum + (e !== 'group' && typeof d[e] === 'number' ? d[e] : 0)
            count = count + (e !== 'group' && typeof d[e] === 'number' ? 1 : 0)
          }
          return (chartType !== 'csat_rating' ? y(Number(Number(sum / count).toFixed(1))) : y(Number(sum / count).toFixed(1)))
        })
        .attr('stroke', 'var(--tertiary-text-color)')
        .attr('stroke-width', '2px')
        .on('mouseover', (event, d) => tooltipFun(event, d, 'in', 'line'))
        .on('mousemove', (event, d) => tooltipFun(event, d, 'in', 'line'))
        .on('mouseout', (event, d) => tooltipFun(event, d, 'out', 'line'))

      const getAvg = function (d) {
        let sum = 0
        let count = 0
        for (const e in d) {
          sum = sum + (e !== 'group' && typeof d[e] === 'number' ? d[e] : 0)
          count = count + (e !== 'group' && typeof d[e] === 'number' ? 1 : 0)
        }
        return chartType !== 'csat_rating' ? Number(Number(sum / count).toFixed(1)) + '%' : Number(Number(sum / count).toFixed(1))
      }

      svg
        .select('#vgparent')
        .append('line')
        .attr('x1', margin.left + 5)
        .attr('x2', function (d) {
          return width - margin.left + 5
        })
        .attr('y1', function (d) {
          return y(Number(overallAvgCsat).toFixed(1))
        })
        .attr('y2', function (d) {
          return y(Number(overallAvgCsat).toFixed(1))
        })
        .attr('stroke', '#dd1d1b')
        .attr('stroke-width', '2px')
        .style('stroke-dasharray', '6, 3')
        .on('mouseover', (event, d) =>
          tooltipFun(event, d, 'in', 'dotted-line')
        )
        .on('mousemove', (event, d) =>
          tooltipFun(event, d, 'in', 'dotted-line')
        )
        .on('mouseout', (event, d) =>
          tooltipFun(event, d, 'out', 'dotted-line')
        )

      function wrap (text, width) {
        text.each(function () {
          const self = d3.select(this)
          let textLength = self.node().getComputedTextLength()
          let textContent = self.text()
          while (textLength > (width - 10) && textContent.length > 0) {
            textContent = textContent.slice(0, -1)
            self.text(textContent + '...')
            textLength = self.node().getComputedTextLength()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .report-csat-over-time-container {
    height: 40vh;
    min-height: 300px;
    padding: 10px 20px;
    transition: width 0.3s ease-out;
    .chart-container {
      display: block;
      width: 100%;
      height: calc(100% - 36px);
    }
    .header {
      color: var(--report-header-color);
      display: flex;
      width:100%;
      opacity: 1;
      font-size: 16px;
      font-family: Quicksand;
      font-weight: 500;
      align-items: center;
      .toggle-button {
      margin-left: auto;
      background-color: transparent;
      outline: none;
      border: none;
      width: 35px;
      height: 35px;
      div {
        display: flex;
      }
      }
    }
  }
  .change-height {
  height: calc(100vh - 15px - var(--page-tracking-height));
  width: calc(100vw - 250px);
  }
  .change-width {
  width: calc(100vw - 100px)
}

@media screen and (min-width: 1700px) {
  .change-height {
    width: calc(100vw - 285px);
    }
  .change-width {
    width: calc(100vw - 120px)
  }
}
</style>
