<template>
  <div class="cip-report-members">
    <span class="wrapper" :class="[active == 'selectLine' ? 'active' : '']">
      <b-button class="toggle-button line" @click="selectLine">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="17" fill="#c2c7de" viewBox="0 0 22 17" v-bind:svg-inline="''" :class="[active == 'selectLine' ? 'active-line' : '','icons']" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g transform="translate(-457 -27)"><path fill="none" stroke="#c2c7de" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M460 41.189l5.024-6.513 5.049 6.512 5.29-10.382"/><circle cx="3" cy="3" r="3" class="cls-2" transform="translate(457 38)"/><circle cx="3" cy="3" r="3" class="cls-2" transform="translate(467 38)"/><circle cx="3" cy="3" r="3" class="cls-2" transform="translate(473 27)"/><circle cx="3" cy="3" r="3" class="cls-2" transform="translate(462 30)"/></g></svg>
      </b-button>
    </span>
    <span class="wrapper" :class="[active == 'selectStack' ? 'active' : '']">
      <b-button class="toggle-button bar" @click="selectStack">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" v-bind:svg-inline="''" :class="[active == 'selectStack' ? 'active-bar' : '','icons']" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#c2c7de" d="M12 16V2h3v14zm-6 0V0h3v16zm-6 0V6h3v10z"/></svg>
      </b-button>
    </span>
    <span class="wrapper">
      <b-button class="toggle-button" @click="toggleReportFullscreen(id)">
        <svg xmlns="http://www.w3.org/2000/svg" class="expand-icon" width="16" height="16" fill="#c2c7de" viewBox="0 0 16 16" v-if="!fullscreen" v-bind:class="'icons'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.328 10.181H0V16h5.819v-2.328H2.328zM0 5.819h2.328V2.328h3.491V0H0zM10.181 0v2.328h3.491v3.491H16V0zM13.672 13.672h-3.491V16H16v-5.819h-2.328z" class="cls-1"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="#c2c7de" width="16" height="16" viewBox="0 0 16 16" v-else="" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.491 16h2.328v-5.819H0v2.328h3.491zM5.819 0H3.491v3.491H0v2.328h5.819zM16 5.819V3.491h-3.491V0h-2.328v5.819zM12.509 12.509H16v-2.328h-5.819V16h2.328z" class="cls-1"/></svg>
      </b-button>
    </span>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

/** This component contains the code for the menu *(chart-type, fullscreen)* of the Reviews over time chart container */
export default {
  props: {
    /** This prop indicates what the last viewed chart was. It helps to persist that view when fullscreen mode is toggled on/off.
     * @values 'line', 'stack'
     */
    lastViewedChartType: {
      type: String
    }
  },
  data () {
    return {
      id: 2
    }
  },
  computed: {
    ...mapState('report', ['reportFullscreen', 'lastViewedChart']),
    ...mapState('common', ['reportFullscreen']),
    fullscreen () {
      return (this.reportFullscreen.id === this.id && this.reportFullscreen.status)
    },
    active () {
      if (this.lastViewedChart.type === 'line') { return 'selectLine' } else { return 'selectStack' }
    }
  },
  methods: {
    ...mapActions('report', ['updateLastViewedChart']),
    ...mapActions('common', ['toggleReportFullscreen']),
    /** This method updates the *lastViewedChart* to have a value of **'line'**
     * @public
     */
    selectLine () {
      this.updateLastViewedChart('line')
    },
    /** This method updates the *lastViewedChart* to have a value of **'stack'**
     * @public
     */
    selectStack () {
      this.updateLastViewedChart('stack')
    }
  }
}
</script>

<style lang="scss" scoped>
.cip-report-members {
  display: flex;
  align-items: center;
  .wrapper {
    .toggle-button {
      background-color: transparent;
      outline: none;
      border: none;
      padding: 0px;
      width: 35px;
      height: 35px;
      .icons {
        display: flex;
      }
    }
    :where(.bar, .line):hover {
      background-color: var(--chart-tabs-background-color);
      .icons {
        fill: white;
        path {
          stroke: white;
        }
      }
    }
    .bar:hover {
      path {
        fill: white;
      }
    }
    .line, .bar {
      border: 1px solid var(--chart-tabs-background-color);
    }
  }
  .icons {
    outline: none;
  }
  .active-line {
    // fill: #4993ff;
    fill: white;
      path {
      stroke: white;
      }
  }
  .active-bar {
      path {
      fill: white;
      }
  }
  span {
    margin-left: 5px;
  }
  .active {
    background: var(--chart-tabs-background-color);
    border-radius: 4px;
  }
}
</style>
