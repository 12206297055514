<template>
  <div class="data-source-container">
    <template v-if="currentModule === 'voc'">
      <span class="show-revies-by">Show reviews by</span>
       <SingleSelectorVue
          ref="groupBySelector"
          label=""
          placeholder="report"
          :list="groupByOptions"
          :selected="selected"
          :showRadio="true"
          @onChange="onChange" />
    </template>
    <template v-if="currentModule === 'voe'">
      <span class="show-revies-by">Reviews by Company</span>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SingleSelectorVue from '../Dropdowns/SingleSelector.vue'
import { groupByOptions } from '../../constant/data'
/**
 * This component is used to render the Group by dropdown on the horizontal barchart on the *Reports* page
 */
export default {
  name: 'GroupBySelector',
  components: {
    SingleSelectorVue
  },
  data () {
    return {
      selected: {},
      groupByOptions: groupByOptions
    }
  },
  computed: {
    ...mapState('report', ['groupBy']),
    ...mapState('common', ['currentModule', 'reportFullscreen'])
  },
  watch: {
    'reportFullscreen.status' (newValue, oldValue) {
      if (!newValue) {
        this.$refs.groupBySelector.open = false
      }
    }
  },
  mounted () {
    this.selected = this.groupBy
  },
  methods: {
    ...mapActions('report', ['updateGroupBy']),
    /** This method handles the behaviour when a new option is selected
     * @public
     */
    onChange (data) {
      this.selected = data
      this.updateGroupBy(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.data-source-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  .show-revies-by {
    padding: 0px 15px 0px 0px;
    font-size: 16px;
    font-family: Quicksand;
    color: var(--report-header-color);
  }
  button {
    font-size: 14px;
    margin-left: 20px;
  }
  .data-source-header {
    background-color: var(--primary);
    border: var(--secondary-border-color);
    color: var(--secondary-text-color);
    height: 36px;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    font-weight: 400;
    justify-content: space-between;
    outline: none;
    font-size: 14px;
    border-radius: 4px;
    position: relative;
    width: 120px;
    .data-source-abs {
      position: absolute;
      width: 180px;
      top: 110%;
      left: 0px;
      z-index: 100;
      border-radius: 4px;
      box-shadow: 0px 3px 6px #00000029;
      background: var(--dropdown-backgroud);
      padding: 5px;
      color: var(--secondary-text-color);
      .custom-radio {
        color: var(--description);
      }
      ul {
        list-style-type: none;
        padding: 5px;
        margin: 0px;
        li {
          display: flex;
          align-items: center;
          .grey {
            color: #354052;
          }
          label {
            margin: 0px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
