<template>
  <div :class="['report-over-time-container sandm',this.reportFullscreen.status?'change-height':'', isCollapsed & this.reportFullscreen.status ? 'change-width': '']">
    <div v-if="!noData" class="header">Reviews over time, {{reviewCount}} Total
      <div class="chart-toggle">
        <ToggleChart />
      </div>
    </div>
      <ReportVerticalStack v-if="reviewOverTimeChartType == 'stack' && !noData" :item="item" />
      <ReportMultiline v-if="reviewOverTimeChartType == 'line' &&  !noData" :item="item" />
      <no-data-container-vue v-if="noData" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ReportVerticalStack from './ReportVerticalStack.vue'
import ReportMultiline from './ReportMultiline'
import ToggleChart from './ToggleChart'
import NoDataContainerVue from '../Common/NoDataContainer/NoDataContainer.vue'

/** This component is the parent container for the 2 Reviews over time charts (Multiline and Vertical-bar-stacked) */
export default {
  name: 'ReviewsOverTime',
  components: {
    ReportVerticalStack,
    ReportMultiline,
    ToggleChart,
    NoDataContainerVue
  },
  // props: ['noData'],.
  props: {
    /** This prop indicates that the minimum data required to render the charts is not present */
    noData: Boolean,
    item: String
  },
  computed: {
    ...mapState('report', ['stack', 'lastViewedChart']),
    ...mapState('common', ['isCollapsed', 'reportFullscreen']),
    reviewCount () {
      return this.stack.reduce((a, b) => a + (Number(b.review_volume) || 0), 0)
    },
    reviewOverTimeChartType () {
      return this.lastViewedChart.type
    }
  },
  methods: {
    ...mapActions('report', ['updateLastViewedChart'])
  }
}
</script>

<style lang="scss" scoped>
  .report-over-time-container {
    padding: 10px 20px;
    height: 40vh;
    min-height: 300px;
    transition: width 0.3s ease-out;
    .header {
      color: var(--report-header-color);
      opacity: 1;
      font-size: 16px;
      flex: 1;
      font-family: Quicksand;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;
      .chart-toggle {
        display: block;
        float: right;
      }
    }
  }
  .change-height {
  height: calc(100vh - 15px - var(--page-tracking-height));
    width: calc(100vw - 250px);
  }
  .change-width {
  width: calc(100vw - 100px)
}

@media screen and (min-width: 1700px) {
  .change-height {
    width: calc(100vw - 285px);
    }
  .change-width {
    width: calc(100vw - 120px)
  }
}
</style>
