<template>
  <div class="report-main">
    <ReportWithSliderVue :numReports="numReports">
      <div :class="[
          'report-donut-chart width-half',
          reportFullscreen.status ? 'width-full' : 'padding-right'
        ]">
        <div class="report-inside">
          <ReportHorizontalVue :noData="noData" :item="item" />
        </div>
      </div>
      <div
        :class="[
          'report-donut-chart width-half',
          reportFullscreen.status ? 'width-full' : ''
        ]">
        <div class="report-inside">
          <ReviewsOverTime :noData="noData" :item="item" />
        </div>
      </div>
      <div
        :class="[
          'report-donut-chart',
          reportFullscreen.status ? 'width-full' : 'padding-right', currentModule === 'voc' ? 'width-half' : 'width-full'
        ]">
        <div class="report-inside">
          <ReportVerticalVue title="Rating over time" :overallScore="overallAvgCsat" :id=3 :stack="stack" :max=5 type="csat_rating" chartId='rating-over-time' :noData="noData" :item="item" />
        </div>
      </div>
      <div v-if="currentModule ==='voc'"
        :class="[
          'report-donut-chart width-half',
          reportFullscreen.status ? 'width-full' : ''
        ]">
        <div class="report-inside">
          <ReportVerticalVue title="Recommendation % over time" :overallScore="overallRecommendationScore" :id=4 :stack="stack" :min=0 :max=100  type="recommendation_score" chartId='recommendation-over-time' :noData="noData" :item="item" />
        </div>
      </div>
      <div v-if="currentModule === 'voc'"
        :class="[
          'report-donut-chart width-full'
        ]">
        <div class="report-inside">
          <bubble-chart-vue :noData="noData" :data="compareRatingOverRecommendation" :legendsText="bubbleLegends" />
        </div>
      </div>
    </ReportWithSliderVue>
  </div>
</template>

<script>
import ReportHorizontalVue from '@/components/Report/ReportHorizontal.vue'
import ReportVerticalVue from '@/components/Report/ReportVertical.vue'
import ReviewsOverTime from '@/components/Report/ReviewsOverTime.vue'
import ReportWithSliderVue from '@/components/Report/ReportWithSlider.vue'
import { mapState, mapActions } from 'vuex'
import { metaTitle } from '@/constant/data'
import BubbleChartVue from '@/components/Report/BubbleChart.vue'

/** This is the parent component/view for the **Reports** page. */
export default {
  name: 'Report',
  metaInfo () {
    return {
      title: this.currentModule === 'voc' ? metaTitle.reports : metaTitle.voeReports
    }
  },
  components: {
    ReportHorizontalVue,
    ReportVerticalVue,
    ReviewsOverTime,
    ReportWithSliderVue,
    BubbleChartVue
  },
  data () {
    return {
      noData: false,
      observer: null,
      bubbleLegends: {
        x: 'Rating',
        y: 'Recommendation'
      }
    }
  },
  computed: {
    ...mapState('filters', [
      'selectedSourceList',
      'selectedProducts',
      'ActiveVocTimePeriod',
      'ActiveVoeTimePeriod',
      'selectedIndustries',
      'subCategories',
      'selectedCompanies',
      'selectedCompaniesVoe',
      'selectedEmployeeStatuses',
      'selectedCategories',
      'selectedBusinessFunc',
      'selectedSeniorityLevel'
    ]),
    ...mapState('report', ['groupBy', 'overall', 'overallAvgCsat', 'overallRecommendationScore', 'stack', 'lastViewedChart', 'compareRatingOverRecommendation']),
    ...mapState('common', ['isCollapsed', 'reportFullscreen', 'currentModule']),
    ...mapState('user', ['userDetails']),
    item () {
      if (this.currentModule === 'voc') {
        return 'product'
      } else {
        return 'company'
      }
    },
    numReports () {
      if (this.currentModule === 'voc') {
        return 5
      } else {
        return 3
      }
    },
    watchable () {
      return JSON.stringify(this.selectedSourceList) + JSON.stringify(this.selectedIndustries) + JSON.stringify(this.selectedCompanies) + JSON.stringify(this.selectedProducts) + JSON.stringify(this.ActiveVocTimePeriod) + JSON.stringify(this.ActiveVoeTimePeriod) + JSON.stringify(this.selectedCompaniesVoe) + JSON.stringify(this.selectedEmployeeStatuses) + JSON.stringify(this.selectedBusinessFunc) + JSON.stringify(this.selectedSeniorityLevel)
    }
  },
  watch: {
    watchable () {
      if (this.watchable.length) {
        this.commonCall()
      }
    },
    // selectedSourceList () {
    //   if (this.selectedSourceList.length) {
    //     this.commonCall()
    //   } else {
    //     this.noData = true
    //   }
    // },
    // selectedProducts () {
    //   if (this.selectedProducts.length) {
    //     this.commonCall()
    //   } else {
    //     this.noData = true
    //   }
    // },
    // selectedIndustries () {
    //   if (this.selectedIndustries.length) {
    //     this.commonCall()
    //   } else {
    //     this.noData = true
    //   }
    // },
    // selectedCompanies () {
    //   if (this.selectedCompanies.length) {
    //     this.commonCall()
    //   } else {
    //     this.noData = true
    //   }
    // },
    // selectedCompaniesVoe () {
    //   if (this.selectedCompaniesVoe.length) {
    //     this.commonCall()
    //   } else {
    //     this.noData = true
    //   }
    // },
    // ActiveVocTimePeriod () {
    //   if (this.ActiveVocTimePeriod != null) {
    //     this.commonCall()
    //   } else {
    //     this.noData = true
    //   }
    // },
    // ActiveVoeTimePeriod () {
    //   if (this.ActiveVoeTimePeriod != null) {
    //     this.commonCall()
    //   } else {
    //     this.noData = true
    //   }
    // },
    groupBy () {
      if (this.groupBy.id != null) {
        this.getGroupByData()
      } else {
        this.noData = true
      }
    },
    stack () {
      if (this.stack.length < 1) {
        this.noData = true
      }
    }
  },
  mounted () {
    this.commonCall()
  },
  methods: {
    ...mapActions('report', ['getReport', 'getGroupedReport']),
    commonCall () {
      this.getStackData()
      this.getGroupByData()
    },
    /**
     * This method makes sure that the minimum data needed to display the charts is present
     * @public
     */
    prepareData () {
      if (
        this.currentModule === 'voc' &&
        this.selectedSourceList.length &&
        this.selectedProducts.length &&
        this.selectedIndustries.length &&
        this.subCategories.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod &&
        this.ActiveVocTimePeriod.group_by
      ) {
        this.noData = false
        const data = {
          module: this.currentModule,
          sources: this.selectedSourceList.map((item) => item.id),
          products: this.selectedProducts,
          category: this.subCategories,
          industries: this.selectedIndustries.map((item) => item.id),
          companies: this.selectedCompanies.map((item) => item.id),
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          groupBy: this.groupBy.id,
          timePeriodGroupBy: this.ActiveVocTimePeriod.group_by,
          subscriptionId: this.selectedCategories.subscriptionId,
          // requestingModuleId: this.userDetails.modules.filter(m => m.module_name === 'voc')[0].module_id
          requestingModuleId: this.userDetails.modules.find(m => m.module_name === 'voc').module_id
        }
        return data
      } else if (
        this.currentModule === 'voe' &&
        this.selectedCompaniesVoe.length &&
        this.selectedEmployeeStatuses.length &&
        this.selectedBusinessFunc.length &&
        this.selectedSeniorityLevel.length &&
        this.ActiveVoeTimePeriod &&
        this.ActiveVoeTimePeriod.group_by
      ) {
        this.noData = false
        const data = {
          module: this.currentModule,
          companies: this.selectedCompaniesVoe.map((item) => item.id),
          employeeStatus: this.selectedEmployeeStatuses.map(item => item.id),
          businessFunc: this.selectedBusinessFunc.map(item => `'${item.name}'`),
          seniorityLevel: this.selectedSeniorityLevel.map(item => `'${item.name}'`),
          startTime: this.ActiveVoeTimePeriod.range[0],
          endTime: this.ActiveVoeTimePeriod.range[1],
          timePeriodGroupBy: this.ActiveVoeTimePeriod.group_by
        }
        return data
      }
      this.noData = true
      return false
    },
    getStackData () {
      if (this.prepareData()) {
        this.getReport(this.prepareData())
      }
    },
    getGroupByData () {
      if (this.prepareData()) {
        this.getGroupedReport(this.prepareData())
      }
    }
  }
}
</script>

<style lang="scss" src="./Report.scss" scoped />
