<template>
  <div class="inner-chart-block" ref="verticalStack">
  <svg ref="verticalStack" id="charts-vertical"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { itemColors } from '@/util/util.js'
import tooltip from '../../util/tooltip'
import { fullScreenMode } from '../../util/util'

/** This component renders a stacked barchart representation of reviews over a particular period of time */
export default {
  name: 'ReportVerticalStack',
  props: {
    item: String
  },
  data () {
    return {
      data: [],
      observer: null
    }
  },
  computed: {
    ...mapState('report', ['stack', 'sortingArray']),
    ...mapState('common', ['reportFullscreen', 'currentModule']),
    ...mapState('filters', ['selectedProducts', 'selectedCompaniesVoe', 'ActiveVocTimePeriod', 'ActiveVoeTimePeriod']),
    reviewCount () {
      return this.stack.reduce((a, b) => a + (Number(b.review_volume) || 0), 0)
    },
    ActiveTimePeriod () {
      if (this.currentModule === 'voc') {
        return this.ActiveVocTimePeriod
      } else {
        return this.ActiveVoeTimePeriod
      }
    }
  },
  watch: {
    stack () {
      this.renderChart()
    }
  },
  mounted () {
    if (this.stack.length) {
      this.renderChart()
    }
    this.resizeWindow()
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    ...mapActions('filters', [
      'updateSelectedProducts',
      'updateActiveVocTimePeriod',
      'updateActiveVoeTimePeriod',
      'updateSelectedCompaniesVoe'
    ]),
    ...mapActions('common', ['resetReportFullscreen']),
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (this.stack.length) {
          if (d3.select('.inner-chart-block').node() != null) { this.renderChart() }
        }
      })
      this.observer.observe(this.$refs.verticalStack)
    },
    /** This method renders the chart
     * @public
     */
    renderChart () {
      const sortingArr = this.sortingArray
      const ActiveTimePeriod = this.ActiveTimePeriod.group_by
      const timeRange = this.ActiveTimePeriod.range
      const currentModule = this.currentModule
      const startDate = moment(timeRange[0], 'YYYY-MM-DD')
      const endDate = moment(timeRange[1], 'YYYY-MM-DD')
      function listQuarters (sDate, eDate) {
        let quarterList = []
        let q = sDate.quarter()
        while (sDate.isBefore(eDate)) {
          q = sDate.quarter()
          quarterList.push('Q' + q + moment(sDate.year(), 'YYYY').format(' \'YY'))
          sDate.add(1, 'month')
        }
        quarterList = [...new Set(quarterList)]
        return quarterList
      }
      function getDomain () {
        let result = []
        if (ActiveTimePeriod === 'month') {
          while (startDate.isBefore(endDate)) {
            result.push(startDate.format('MMM \'YY'))
            startDate.add(1, 'month')
          }
        } else {
          result = listQuarters(startDate, endDate)
        }
        return result
      }
      d3.selectAll('#charts-vertical > *').remove()
      const items = [
        ...new Set(this.stack.map((item) => item[`${this.item}_name`]))
      ]
      items.sort(function (a, b) {
        return sortingArr.indexOf(a) - sortingArr.indexOf(b)
      })
      const dates = []
      for (const data of this.stack) {
        dates.push(
          moment(`${data.month}-${data.year_of_review}`, 'M-YYYY').format(
            'DD-MM-YYYY'
          )
        )
      }
      const data = [...new Set(dates.map((item) => item))].sort()
      const finalData = []
      for (const date of data) {
        const temp = {}
        temp.date = date
        for (const check of this.stack) {
          if (
            moment(`${check.month}-${check.year_of_review}`, 'M-YYYY').format(
              'DD-MM-YYYY'
            ) === date
          ) {
            temp[check[`${this.item}_name`]] = check.review_volume
          }
        }
        finalData.push(temp)
      }

      finalData.forEach(function (d, i) {
        d = type(d, i)
      })
      const layers = d3
        .stack()
        .keys(items.reverse())
        .offset(d3.stackOffsetDiverging)(finalData)

      const margin = {
        top: 30,
        right: 40,
        bottom: 32,
        left: 40
      }

      const width = d3.select('.inner-chart-block').node().getBoundingClientRect().width
      const height =
        d3.select('.inner-chart-block').node().getBoundingClientRect().height
      const svg = d3
        .select('#charts-vertical')
        .attr('width', width)
        .attr('height', height)

      const x = d3
        .scaleBand()
        .rangeRound([margin.left, width])
        .padding(0.2)

      // x.domain(
      //   finalData.map(function (d) {
      //     return ActiveTimePeriod === 'quarter' ? 'Q' + d.quarter : d.date
      //   })
      // )

      x.domain(
        getDomain()
      )

      const y = d3.scaleLinear().range([height - margin.bottom, margin.top])

      y.domain([d3.min(layers, stackMin), d3.max(layers, stackMax)]).nice()

      function stackMin (layers) {
        return d3.min(layers, function (d) {
          return d[0]
        })
      }

      function stackMax (layers) {
        return d3.max(layers, function (d) {
          return d[1]
        })
      }

      svg
        .append('g')
        .attr('id', 'botton-path')
        .attr('transform', 'translate(0,' + y(0) + ')')
        .call(d3.axisBottom(x))
        .select('path')
        .style('display', 'none')

      d3.select('#botton-path')
        .selectAll('text')
        .each(function (d) {})

      svg
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',0)')
        .call(d3.axisLeft(y).ticks(5))
        .select('path')
        .style('display', 'none')
      svg
        .selectAll('.tick')
        .attr('class', 'stack-xaxis')
        .select('line')
        .style('display', 'none')
      svg
        .selectAll('.stack-xaxis')
        .select('text')
        .attr('fill', 'var(--reports-label-color)')
        .attr('font-size', 12)
        .attr('font-family', 'Quicksand, sans-serif')
        .attr('fill-opacity', '0.8')

      function makeYGridlines () {
        return d3.axisLeft(y).ticks(5)
      }

      svg
        .append('g')
        .attr('class', 'grid')
        .attr('id', 'lables')
        .attr('transform', 'translate(' + margin.left + ',0)')
        .call(
          makeYGridlines()
            .tickSize(-(width - 50))
            .tickFormat('')
        )
        .style('stroke-dasharray', '4, 4')

      svg.selectAll('.grid').select('.domain').style('display', 'none')

      svg
        .selectAll('#lables')
        .selectAll('g')
        .select('line')
        .attr('stroke', 'var(--chart-background-dotted-line-color)')
        .attr('stroke-width', 1)
      svg
        .selectAll('#lables')
        .selectAll('g')
        .each(function (d, index) {
          if (index === 0) {
            d3.select(this)
              .select('line')
              .attr('stroke', 'var(--report-chart-bottom-background-color)')
          }
        })

      const maing = svg.append('g').selectAll('g').data(layers)
      const g = maing
        .enter()
        .append('g')
        .attr('class', 'sbg')
        .attr('fill', (d, index) => {
          if (currentModule === 'voc') {
            return itemColors(
              this.selectedProducts.map((data) => data.name).indexOf(d.key)
            )
          } else if (currentModule === 'voe') {
            return itemColors(
              this.selectedCompaniesVoe.map((data) => data.name).indexOf(d.key)
            )
          }
        })

      const drillDown = (event, d, type) => {
        if (this.reportFullscreen.status) {
          this.resetReportFullscreen()
          fullScreenMode(document, 'off')
        }
        const output = this.selectedProducts.filter(({ name }) => name === d.key)
        let range = []
        if (this.ActiveTimePeriod.group_by === 'quarter') {
          range = [moment(d.data.quarter, 'Q \'YY').startOf('quarter').format('YYYY-MM-DD'), moment(d.data.quarter, 'Q \'YY').endOf('quarter').format('YYYY-MM-DD')]
        } else {
          range = [moment(d.data.date, 'MMM \'YY').startOf('month').format('YYYY-MM-DD'), moment(d.data.date, 'MMM \'YY').endOf('month').format('YYYY-MM-DD')]
        }
        const timeObject = {}
        timeObject.group_by = this.ActiveTimePeriod.group_by
        timeObject.range = range
        if (currentModule === 'voc') {
          this.updateActiveVocTimePeriod(timeObject)
        } else if (currentModule === 'voe') {
          this.updateActiveVoeTimePeriod(timeObject)
        }
        if (output.length && currentModule === 'voc') {
          this.updateSelectedProducts([output[0]])
        } else if (output.length && currentModule === 'voe') {
          this.updateSelectedCompaniesVoe([output[0]])
        }
        tooltipFun(event, d, 'out')
        if (currentModule === 'voc') {
          this.$router.push({ name: 'comments' })
        } else if (currentModule === 'voe') {
          this.$router.push({ name: 'voeComments', path: 'comments' })
        }
      }

      function tooltipFun (event, d, type) {
        let data = {}
        switch (type) {
          case 'in':
            // eslint-disable-next-line no-case-declarations
            const format = ActiveTimePeriod === 'quarter' ? 'Quarter' : 'Month'
            data = {
              '': d.key,
              [format]:
                ActiveTimePeriod === 'quarter'
                  ? `Q${d.data.quarter}`
                  : d.data.date,
              'Review volume': (d[0] - d[1]) * -1
            }
            break
        }
        tooltip(event, data, type)
      }

      g
        .selectAll('rect')
        .data(function (d) {
          d.forEach(function (d1) {
            d1.key = d.key
            return d1
          })
          return d
        })
        .enter()
        .append('rect')
        .attr('class', function (d) {
          return d.data.date.replace("'", '')
        })
        .attr('data', function (d) {
          const data = {}
          data.key = d.key
          data.value = d.data[d.key]
          let total = 0
          // eslint-disable-next-line array-callback-return
          items.map(function (d1) {
            total = total + d.data[d1]
          })
          data.total = total
          return JSON.stringify(data)
        })
        .attr('width', x.bandwidth)
        .attr('x', function (d) {
          return ActiveTimePeriod === 'quarter'
            ? x('Q' + d.data.quarter)
            : x(d.data.date)
        })
        .attr('y', function (d) {
          return y(d[1])
        })
        .attr('height', function (d) {
          if (!isNaN(y(d[0]) - y(d[1]))) {
            return y(d[0]) - y(d[1])
          }
        })
        .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
        .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
        .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))
        .on('click', (event, d) => drillDown(event, d, 'out', 'bar'))

      const svtext = d3
        .select('.sbg:last-of-type')
        .selectAll('.text')
        .data(function (d) {
          d.forEach(function (d1) {
            d1.key = d.key
            return d1
          })
          return d
        })
      svtext.exit().remove()
      svtext
        .enter()
        .append('text')
        .attr('class', 'text')
        .attr('text-anchor', 'middle')
        .merge(svtext)
        .attr('x', (d) => {
          const value = ActiveTimePeriod === 'quarter'
            ? x('Q' + d.data.quarter) + x.bandwidth() / 2
            : x(d.data.date) + x.bandwidth() / 2
          if (!isNaN(value)) {
            return value
          }
        })
        .attr('y', function (d, i) {
          let total = 0
          // eslint-disable-next-line array-callback-return
          items.map(function (d1) {
            total = total + (d.data[d1] ? d.data[d1] : 0)
          })
          return y(total) - 4
        })
        .text(function (d, i) {
          let total = 0
          // eslint-disable-next-line array-callback-return
          items.map(function (d1) {
            total = total + (d.data[d1] ? d.data[d1] : 0)
          })
          return total
        })
        .attr('fill', 'var(--secondary-text-color)')
        .attr('font-size', '12px')
        .attr('font-family', 'Quicksand, sans-serif')
        .attr('id', (d) => x(d.data.date) + x.bandwidth() / 2)
        .attr('class', 'total-text')

      function type (d, i) {
        d.quarter =
          Number(moment(d.date, 'DD-MM-YYYY').format('MM')) + ' ' + moment(d.date, 'DD-MM-YYYY').format('\'YY')
        d.date = moment(d.date, 'DD-MM-YYYY').format('MMM \'YY')
        items.forEach(function (c) {
          d[c] = +d[c]
        })
        return d
      }

      let totalWidth = 0
      svg.select('#botton-path')
        .selectAll('.stack-xaxis')
        .each(function (d, index, data) {
          const temp = d3
            .select(this)
            .select('text')
            .node()
            .getBoundingClientRect().width
          totalWidth += temp
        })
      if (totalWidth + margin.right + margin.left > width) {
        svg.select('#botton-path')
          .selectAll('.stack-xaxis')
          .each(function (d, index, data) {
            if (!(index === 0 || index === data.length - 1)) {
              d3.select(this).select('text').remove()
            }
          })
      }
      let maxTotalWidth = 0
      svg.selectAll('.total-text').each(function (d, index, data) {
        const currentWidth = d3
          .select(this)
          .node()
          .getBoundingClientRect().width
        maxTotalWidth = maxTotalWidth > currentWidth ? maxTotalWidth : currentWidth
      })
      if (maxTotalWidth > x.bandwidth()) {
        svg.selectAll('.total-text').remove()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inner-chart-block {
      display: block;
      width: 100%;
      height: calc(100% - 36px);
}
</style>
