<template>
  <div
    ref="compare"
    :class="[
      'compare-container',
      reportFullscreen.status ? 'change-height' : '',
      isCollapsed & this.reportFullscreen.status ? 'change-width' : ''
    ]">
    <div v-if="!noData && !hideHeader" class="header">
      Compare Rating vs Recommendation %
      <b-button class="toggle-button" @click="toggleReportFullscreen(5)">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="expand-icon" width="16" height="16" fill="#c2c7de" viewBox="0 0 16 16" v-if="!fullscreen" v-bind:class="'icons'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.328 10.181H0V16h5.819v-2.328H2.328zM0 5.819h2.328V2.328h3.491V0H0zM10.181 0v2.328h3.491v3.491H16V0zM13.672 13.672h-3.491V16H16v-5.819h-2.328z" class="cls-1"/></svg>
          <svg xmlns="http://www.w3.org/2000/svg" fill="#c2c7de" width="16" height="16" viewBox="0 0 16 16" v-else="" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.491 16h2.328v-5.819H0v2.328h3.491zM5.819 0H3.491v3.491H0v2.328h5.819zM16 5.819V3.491h-3.491V0h-2.328v5.819zM12.509 12.509H16v-2.328h-5.819V16h2.328z" class="cls-1"/></svg>
        </div>
      </b-button>
    </div>
    <div v-if="!noData" :class="[`compare-rating-container`]" :style="cssVars">
      <svg id="compare-rating-recommendation"></svg>
    </div>
    <no-data-container-vue v-if="noData" />
  </div>
</template>

<script>
import * as d3 from 'd3'
import tooltip from '../../util/tooltip'
import { mapState, mapActions } from 'vuex'
import { itemColors } from '@/util/util.js'
import NoDataContainerVue from '../Common/NoDataContainer/NoDataContainer.vue'
import { fullScreenMode } from '../../util/util'
/**
 * This component is used to render a bubble chart. It is currently being used on the *Reports* and *Competitive Positioning* page.
 */
export default {
  name: 'BubbleChart',
  components: {
    NoDataContainerVue
  },
  props: {
    /** If *true*, the chart will not be rendered. */
    noData: {
      type: Boolean
    },
    /** The data for the Bubble Chart */
    data: {
      type: Array
    },
    /** Toggle for displaying the header (Title + Fullscreen button) */
    hideHeader: {
      type: Boolean,
      default: false
    },
    /** An object containing 2 key-value pairs. One pair is the value for the x-axis label, and the other contains the value for the y-axis label.
     * @values {x: Number, y: Number}
    */
    legendsText: {
      type: Object
    },
    /** An object containing the values for the x and y axis domains. If no value is given, the chart dynamically renders the bubbles, trying to spread them out as much as possible.  */
    domains: {
      type: Object,
      default: null
    },
    /** An array containing the values for *Standard Avg* and *Functional Avg*. Usually only needed for the WinLoss Page. Draws vertical and horizontal dashed lines at the points specified in the array
     * @values [Number, Number]
     */
    categoryAvg: {
      type: Array,
      default: null
    },
    /** Used on the WinLoss page to gray out the bubbles that are not being compared  */
    selectiveColoring: {
      type: Boolean,
      default: false
    },
    /** Used to identify the tooltip that is to be rendered. By default, it will display the tooltip for the Report page bubble chart.
     * @values 'winloss'
     */
    tooltipType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      observer: null
    }
  },
  computed: {
    // ...mapState('report', ['compareRatingOverRecommendation']),
    ...mapState('filters', ['selectedProducts']),
    ...mapState('common', ['isCollapsed', 'reportFullscreen']),
    ...mapState('winLoss', ['wlSelectedProductLeft', 'wlSelectedProductRight']),
    fullscreen () {
      return (
        this.reportFullscreen.id === this.id && this.reportFullscreen.status
      )
    },
    cssVars () {
      return {
        '--container-height': this.hideHeader ? '100%' : 'calc(100% - 36px)'
      }
    }
  },
  watch: {
    // compareRatingOverRecommendation () {
    //   if (this.compareRatingOverRecommendation.length) {
    //     this.renderChart()
    //   }
    // }
    categoryAvg () {
      // console.log(this.categoryAvg)
    },
    data () {
      if (this.data.length) {
        this.renderChart()
      }
    },
    noData () {
      // console.log(this.noData)
    }
  },
  mounted () {
    if (this.data.length) {
      this.renderChart()
    }
    this.resizeWindow()
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    ...mapActions('filters', ['updateSelectedProducts']),
    ...mapActions('common', [
      'toggleReportFullscreen',
      'resetReportFullscreen'
    ]),
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (this.data.length) {
          if (d3.select('.compare-rating-container').node() != null) {
            this.renderChart()
          }
        }
      })
      this.observer.observe(this.$refs.compare)
    },
    /** This method renders a Bubble Chart
     * @public
     */
    renderChart () {
      const tooltipType = this.tooltipType
      const categoryAvg = this.categoryAvg
      const categoryStandardAvg =
        this.categoryAvg?.find((d) => d.is_standard === true)
          ?.overall_rating || null
      const categoryFunctionalAvg =
        this.categoryAvg?.find((d) => d.is_standard === false)
          ?.overall_rating || null
      d3.selectAll('#compare-rating-recommendation > *').remove()

      const margin = { top: 20, right: 70, bottom: 90, left: 60 }
      const width = d3
        .select('.compare-rating-container')
        .node()
        .getBoundingClientRect().width
      const height = d3
        .select('.compare-rating-container')
        .node()
        .getBoundingClientRect().height
      const zoom = d3
        .zoom()
        .scaleExtent([1, 8])
        .translateExtent([
          [0, 0],
          [width, height]
        ])
        .extent([
          [0, 0],
          [width, height]
        ])
        .on('zoom', zoomed)

      const svg = d3
        .select('#compare-rating-recommendation')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      const minBubbleVolume = d3.min(this.data.map((data) => data.volume))
      const maxBubbleVolume = d3.max(this.data.map((data) => data.volume))
      const z = d3
        .scaleLinear()
        .domain([
          minBubbleVolume,
          maxBubbleVolume
        ])
        .range([10, 50])

      const maxCsatRating = d3.max(this.data.map((data) => data.x))
      const minCsatRating = d3.min(this.data.map((data) => data.x))
      const x = d3
        .scaleLinear()
        .domain(
          this.domains
            ? [
              d3.min([
                ...this.data.map((data) => data.x),
                parseFloat(categoryFunctionalAvg)
              ]) - 0.5,
                d3.max([
                  ...this.data.map((data) => data.x),
                  parseFloat(categoryFunctionalAvg)
                ]) + 0.5
              ]
            : [
                minCsatRating - 0.5,
                maxCsatRating + 0.5
              ]
        )
        .range([0, width - margin.right])

      const minRecommendationRating = d3.min(this.data.map((data) => data.y))
      const maxRecommendationRating = d3.max(this.data.map((data) => data.y))
      const y = d3
        .scaleLinear()
        .domain(
          this.domains
            ? [
                d3.min([
                  ...this.data.map((data) => data.y),
                  parseFloat(categoryStandardAvg)
                ]) - 0.5,
                d3.max([
                  ...this.data.map((data) => data.y),
                  parseFloat(categoryStandardAvg)
                ]) + 0.5
              ]
            : (minRecommendationRating && maxRecommendationRating)
              ? [
                    minRecommendationRating - 10,
                    maxRecommendationRating + 10
                  ]
              : [
                    0,
                    100
                  ]
        )
        .range([height - margin.bottom, 0])

      const defs = svg.append('defs')
      defs
        .append('SVG:clipPath')
        .attr('id', 'clip')
        .append('SVG:rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width - margin.right)
        .attr('height', height - margin.bottom)

      const xAxis = d3.axisBottom(x).tickFormat(t => { if (t >= 0) return t })
      const yAxis = d3.axisLeft(y).tickFormat(t => { if (t <= 100 && t >= 0) return t })
      const xAxisPlot = svg
        .append('g')
        .attr('transform', `translate(0, ${height - margin.bottom})`)
        .call(xAxis)
      // xAxisPlot.selectAll('.tick').select('line').remove()
      // xAxisPlot.selectAll('.tick').each(function (d) {
      //   d3.select(this).append('line').attr('id', 'line').attr('y2', -height + margin.bottom)
      // })

      const yAxisPlot = svg.append('g').call(yAxis)
      yAxisPlot.selectAll('.tick').select('line').remove()

      yAxisPlot.selectAll('.tick').each(function (d) {
        d3.select(this)
          .append('line')
          .attr('id', 'line')
          .attr('x2', width - margin.right)
      })

      const zoomContainer = svg.append('rect')
        .attr('width', width - margin.right)
        .attr('height', height - margin.bottom)
        .style('pointer-events', 'all')
        .attr('fill', 'none')
      // .attr('stroke', 'white')

      zoomContainer
        .call(zoom)
        .on('dblclick.zoom', function (d) {
          zoomContainer.transition().duration(500).call(zoom.transform, d3.zoomIdentity)
        })

      svg.append('defs').append('pattern')
        .attr('id', 'diagonalHatch')
        .attr('width', 4)
        .attr('height', 8)
        .attr('patternUnits', 'userSpaceOnUse')
        .attr('patternTransform', 'rotate(45)')
        .append('path')
        .attr('d', 'M -1,2 l 6,0')
        .attr('stroke', 'white')
        .attr('stroke-width', 1)

      const chartBody = svg.append('g').attr('clip-path', 'url(#clip)')

      function zoomed (event, d) {
        const newXScale = event.transform.rescaleX(x)
        const newYScale = event.transform.rescaleY(y)

        xAxisPlot.call(xAxis.scale(newXScale))
        yAxisPlot.call(yAxis.scale(newYScale))

        // xAxisPlot.selectAll('.tick').each(function (d) {
        //   d3.select(this).selectAll('line').remove()
        //   d3.select(this).append('line').attr('id', 'line').attr('y2', -height + margin.bottom)
        // })

        svg
          .selectAll('.tick')
          .selectAll('text')
          .attr('font-family', 'Quicksand, sans-serif')
          .attr('fill', '#A8AAB7')
          .attr('font-weight', 500)

        xAxisPlot.selectAll('.tick text')
          .attr('fill', 'var(--reports-label-color)')
          .attr('font-weight', 500)

        yAxisPlot.selectAll('.tick').each(function (d) {
          d3.select(this).select('#line').remove()

          d3.select(this)
            .append('line')
            .attr('id', 'line')
            .attr('x2', width - margin.right)
        })
        svg
          .selectAll('.tick')
          .selectAll('text')
          .attr('font-family', 'Quicksand, sans-serif')
        svg
          .selectAll('.tick')
          .selectAll('line')
          .attr('stroke', 'var(--chart-background-dotted-line-color)')
        svg
          .selectAll('.domain')
          .attr('stroke', 'var(--chart-background-dotted-line-color)')
        svg.selectAll('.item').each(function (d, i) {
          d3.select(this)
            .attr('cx', (d) => d.x ? newXScale(d.x) : newXScale(0))
            .attr('cy', (d) => d.y ? newYScale(d.y) : newYScale(0))
            .attr('r', (d) => z(d.volume))
        })
        svg.selectAll('.hatch').each(function (d, i) {
          d3.select(this)
            .attr('cx', (d) => newXScale(d3.select(this).attr('data-x')))
            .attr('cy', (d) => newYScale(0))
            .attr('r', (d) => z(d3.select(this).attr('data-z')))
        })
        if (categoryAvg) {
          standardAvg.attr('y1', newYScale(categoryStandardAvg))
          standardAvg.attr('y2', newYScale(categoryStandardAvg))
          functionalAvg.attr('x1', newXScale(categoryFunctionalAvg))
          functionalAvg.attr('x2', newXScale(categoryFunctionalAvg))
        }
      }
      svg
        .selectAll('.tick')
        .selectAll('text')
        .attr('font-family', 'Quicksand, sans-serif')
        .attr('fill', 'var(--bubble-y-axis-text-color)')
        .attr('font-weight', 500)

      xAxisPlot.selectAll('.tick text')
        .attr('fill', 'var(--reports-label-color)')
        .attr('font-weight', 500)

      svg
        .selectAll('.tick')
        .selectAll('line')
        .attr('stroke', 'var(--chart-background-dotted-line-color)')
      svg
        .selectAll('.domain')
        .attr('stroke', 'var(--chart-background-dotted-line-color)')

      function lineTooltipFunction (event, d, type) {
        let obj = {}
        if (d.name === 'Functional average') {
          obj = {
            'Average functional rating': d.avg ? d.avg : 'N/A'
          }
        } else {
          obj = {
            'Average standard rating': d.avg ? d.avg : 'N/A'
          }
        }
        tooltip(event, obj, type)
      }

      function tooltipFun (event, d, type) {
        let obj = {}
        if (tooltipType === 'winloss') {
          obj = {
            '': d.product_name,
            'Rating': d.volume,
            'Standard capabilities satisfaction': d.y ? d.y + '%' : 'N/A',
            'Functional capabilities satisfaction': d.x ? d.x + '%' : 'N/A'
          }
        } else {
          obj = {
            '': d.product_name,
            'Rating': d.x ? d.x : 'N/A',
            'Recommendation': d.y ? d.y + '%' : 'N/A',
            'Review Volume': d.volume ? d.volume : 'N/A'
          }
        }
        tooltip(event, obj, type)
      }
      chartBody
        .attr('transform-box', 'fill-box')
        .append('g')
        .attr('transform-box', 'fill-box')
        .selectAll('dot')
        .data(this.data)
        .join('circle')
        .attr('class', 'item')
        .attr('cx', (d) => d.x ? x(d.x) : x(0))
        .attr('cy', (d) => d.y ? y(d.y) : y(0))
        .attr('r', (d) => z(d.volume))
        .style('fill', (d) => {
          const color = itemColors(
            this.selectedProducts
              .map((data) => data.name)
              .indexOf(d.product_name)
          )
          if (this.selectiveColoring) {
            if (d.product_name === this.wlSelectedProductLeft.name) {
              return 'var(--radar-primary)'
            } else if (d.product_name === this.wlSelectedProductRight.name) {
              return 'var(--radar-secondary)'
            } else {
              return 'var(--word-cloud-text-color)'
            }
          } else {
            return color
          }
        })
        .style('opacity', '1')
        .attr('stroke', 'none')
        .style('pointer-events', 'all')
        .on('click', (event, d) => {
          if (this.reportFullscreen.status) {
            this.resetReportFullscreen()
            fullScreenMode(document, 'off')
          }
          const data = this.selectedProducts.filter(
            ({ name }) => name === d.product_name
          )
          if (data.length) {
            this.updateSelectedProducts([data[0]])
          }
          this.$router.push({ name: 'comments' })
          tooltip(event, null, 'out')
        })
        .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
        .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
        .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))

      chartBody.selectAll('circle')
        .each(function (d, i) {
          if (d.y === null) {
            chartBody
              .select('g')
              .append('circle')
              .attr('class', 'hatch')
              .attr('fill', 'url(#diagonalHatch)')
              .attr('cx', () => x(d.x))
              .attr('cy', () => { return y(0) })
              .attr('r', () => z(d.volume))
              .attr('data-x', d.x)
              .attr('data-y', d.y)
              .attr('data-z', d.volume)
              .style('opacity', '1')
              .attr('stroke', 'none')
              .style('pointer-events', 'all')
              .on('click', (event, d) => {
                if (this.reportFullscreen.status) {
                  this.resetReportFullscreen()
                  fullScreenMode(document, 'off')
                }
                const data = this.selectedProducts.filter(
                  ({ name }) => name === d.product_name
                )
                if (data.length) {
                  this.updateSelectedProducts([data[0]])
                }
                this.$router.push({ name: 'comments' })
                tooltip(event, null, 'out')
              })
              .on('mouseover', (event) => tooltipFun(event, d, 'in'))
              .on('mousemove', (event) => tooltipFun(event, d, 'in'))
              .on('mouseout', (event) => tooltipFun(event, d, 'out'))
          }
        })

      let standardAvg = null
      let functionalAvg = null

      if (categoryAvg) {
        standardAvg = chartBody
          // .select('#vgparent')
          .append('line')
          .attr('x1', function (d) {
            return x(x.domain()[0])
          })
          .attr('x2', function (d) {
            return x(x.domain()[1])
          })
          .attr('y1', function (d) {
            return y(Number(categoryStandardAvg).toFixed(1))
          })
          .attr('y2', function (d) {
            return y(Number(categoryStandardAvg).toFixed(1))
          })
          .attr('d', function (d) {
            return Number(categoryStandardAvg).toFixed(1)
          })
          .attr('stroke', '#dd1d1b')
          .attr('stroke-width', '2px')
          .style('stroke-dasharray', '6, 3')
          .on('mouseover', function (event) {
            const d = { name: 'Standard average', avg: this.getAttribute('d') }
            lineTooltipFunction(event, d, 'in')
          })
          .on('mousemove', function (event) {
            const d = { name: 'Standard average', avg: this.getAttribute('d') }
            lineTooltipFunction(event, d, 'in')
          })
          .on('mouseout', function (event) {
            const d = { name: 'Standard average', avg: this.getAttribute('d') }
            lineTooltipFunction(event, d, 'out')
          })
      }

      if (categoryAvg) {
        functionalAvg = chartBody
          // .select('#vgparent')
          .append('line')
          .attr('x1', function (d) {
            return x(categoryFunctionalAvg)
          })
          .attr('x2', function (d) {
            return x(categoryFunctionalAvg)
          })
          .attr('y1', function (d) {
            return y(y.domain()[0])
          })
          .attr('y2', function (d) {
            return y(y.domain()[1])
          })
          .attr('d', function (d) {
            return Number(categoryFunctionalAvg).toFixed(1)
          })
          .attr('stroke', '#dd1d1b')
          .attr('stroke-width', '2px')
          .style('stroke-dasharray', '6, 3')
          .on('mouseover', function (event) {
            const d = {
              name: 'Functional average',
              avg: this.getAttribute('d')
            }
            lineTooltipFunction(event, d, 'in')
          })
          .on('mousemove', function (event) {
            const d = {
              name: 'Functional average',
              avg: this.getAttribute('d')
            }
            lineTooltipFunction(event, d, 'in')
          })
          .on('mouseout', function (event) {
            const d = {
              name: 'Functional average',
              avg: this.getAttribute('d')
            }
            lineTooltipFunction(event, d, 'out')
          })
      }

      const legends = svg.append('g')

      legends
        .append('text')
        // .attr('transform', `translate(-35,${height / 2})rotate(270)`)
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - margin.left)
        .attr('x', 0 - height / 2 + margin.top)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .attr('stroke', 'none')
        .attr('font-size', '16px')
        .attr('font-family', 'Quicksand, sans-serif')
        .attr('fill', 'var(--bubble-chart-legend-color)')
        .attr('stroke-width', '1px')
        .text(this.legendsText.y)
      legends
        .append('text')
        .attr(
          'transform',
          'translate(' + width / 2 + ' ,' + (height - 30) + ')'
        )
        .attr('dx', '0')
        .attr('dy', '0em')
        .style('text-anchor', 'middle')
        .attr('stroke', 'none')
        .attr('font-size', '16px')
        .attr('font-family', 'Quicksand, sans-serif')
        .attr('fill', 'var(--bubble-chart-legend-color)')
        .attr('stroke-width', '1px')
        .text(this.legendsText.x)
    }
  }
}
</script>

<style lang="scss" scoped>
.compare-container {
  height: 60vh;
  min-height: 250px;
  padding: 10px 20px;
  transition: width 0.3s ease-out;
  .compare-rating-container {
    display: block;
    width: 100%;
    height: var(--container-height); // calc(100% - 36px);
  }
  .header {
    color: var(--report-header-color);
    display: flex;
    width: 100%;
    opacity: 1;
    font-size: 18px;
    font-family: Quicksand;
    font-weight: 500;
    height: 36px;
    align-items: center;
    .toggle-button {
      margin-left: auto;
      background-color: transparent;
      outline: none;
      border: none;
      width: 35px;
      height: 35px;
      div {
        display: flex;
      }
    }
  }
}
.change-height {
  height: calc(100vh - 15px - var(--page-tracking-height));
  width: calc(100vw - 250px);
}
.change-width {
  width: calc(100vw - 100px);
}

@media screen and (min-width: 1700px) {
  .change-height {
    width: calc(100vw - 285px);
    }
  .change-width {
    width: calc(100vw - 120px)
  }
}
</style>
